import { eventbus } from ".";

export const platformEvents = eventbus<{
  generalSuccess: (message: string) => void;
  generalFailure: (message: string) => void;
  itemAddedToCart: () => void;
  itemRemovedFromCart: () => void;
  subscriptionAdded: (name?: string) => void;
  subscriptionRemoved: (name?: string) => void;
}>();
