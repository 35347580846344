import React, { useRef, useState } from "react";
import { Link, useLocation, useParams } from "react-router-dom";
import BrowseIcon from "../icons/browse";
import SubsIcon from "../icons/subscriptions";
import LatestIcon from "../icons/latest";
import PopularIcon from "../icons/popular";
import MoreIcon from "../icons/morehorizontal";
import CrossIcon from "../icons/cross";
import useOutsideBounds from "../services/useOutsideBounds";
import useSwipe from "../services/useSwipe";
import { Subscription } from "../types/Subscription";
import { Category } from "../types/Category";

interface Links {
  text: string;
  href: string;
  icon: React.ReactNode;
}

const links: Links[] = [
  { text: "Browse", href: "/browse", icon: <BrowseIcon /> },
  { text: "Subscriptions", href: "/subscriptions", icon: <SubsIcon /> },
  { text: "Latest", href: "/latest", icon: <LatestIcon /> },
  { text: "Popular", href: "/popular", icon: <PopularIcon /> },
];

interface Props {
  outsideClicked: () => void;
  categories?: Category[];
  subscriptions?: Subscription[];
  selectedCategories: Category[];
  handleCategoryClick: (category: Category) => void;
  handleCategoryRemove: (category: Category) => void;
  handleSubscriptionClick: (subscription: Subscription) => void;
}

interface CategoryListItemProps {
  category: Category;
  handleCategoryClick: (category: Category) => void;
  depth: number;
  selectedCategories: Category[];
}

function CategoryListItem({
  category,
  handleCategoryClick,
  depth,
  selectedCategories,
}: CategoryListItemProps) {
  const paddingLeft = depth * 16;
  return (
    <>
      <div
        className="flex cursor-pointer gap-sm rounded-md px-md py-sm text-slate-100 hover:bg-slate-400"
        style={{ paddingLeft: paddingLeft }}
        onClick={() => handleCategoryClick(category)}
      >
        <div className="flex h-[24px] w-[24px]">
          <span className="m-auto h-sm w-sm rounded-full bg-[#D0B8F9]"></span>
        </div>
        <span className="flex-1 truncate">{category.name}</span>
        {selectedCategories?.includes(category) && (
          <button>
            <CrossIcon />
          </button>
        )}
      </div>
      {category.all_children &&
        category.all_children.map((child, index) => (
          <CategoryListItem
            key={index}
            category={child}
            depth={depth + 1}
            handleCategoryClick={handleCategoryClick}
            selectedCategories={selectedCategories}
          />
        ))}
    </>
  );
}

export default function Sidebar({
  categories,
  subscriptions,
  selectedCategories,
  handleCategoryClick,
  handleCategoryRemove,
  handleSubscriptionClick,
  outsideClicked,
}: Props) {
  const location = useLocation();

  const [position, setPosition] = useState({ x: 0, y: 0 });

  const handleSwipeMove = (delta: { x: number; y: number }) => {
    setPosition({ x: position.x + delta.x, y: position.y + delta.y });
  };

  const handleTouchEnd = () => {
    setPosition({ x: 0, y: 0 });
  };

  const handleThresholdMet = () => {
    outsideClicked();
    setPosition({ x: 0, y: 0 });
  };

  useSwipe({
    threshold: 150,
    direction: "left",
    touchStart: () => {},
    touchMove: handleSwipeMove,
    touchEnd: handleTouchEnd,
    thresholdMet: handleThresholdMet,
  });

  const wrapperRef = useRef(null);
  useOutsideBounds(wrapperRef, () => {
    outsideClicked();
  });

  return (
    <aside
      style={{ left: `${position.x}px`, top: `${position.y}px` }}
      ref={wrapperRef}
      className="custom-scrollbar fixed z-20 h-full w-10/12 max-w-sm space-y-lg overflow-y-auto bg-slate-500 px-md py-lg xl:static"
    >
      <div className="space-y-sm">
        {links.map((link, index) => (
          <Link
            key={index}
            to={link.href}
            className={`flex gap-sm rounded-md px-md py-sm ${
              location.pathname === link.href
                ? "bg-slate-400 hover:bg-slate-300"
                : "text-slate-100 hover:bg-slate-400"
            }`}
          >
            {link.icon}
            <span>{link.text}</span>
          </Link>
        ))}
      </div>

      <div className="space-y-sm">
        <div className="flex justify-between px-md py-sm">
          <h2 className="font-semibold text-slate-100">Subscriptions</h2>
          <button>
            <MoreIcon />
          </button>
        </div>
        {subscriptions &&
          subscriptions.map((sub, index) => (
            <Link
              key={"s" + index}
              onClick={() => handleSubscriptionClick(sub)}
              className={`flex gap-sm rounded-md px-md py-sm  ${window.location.href === sub.creator.url ? "bg-slate-400 hover:bg-slate-300" : "text-slate-100 hover:bg-slate-400"}`}
              to={sub.creator.url}
            >
              <img
                src={sub.creator.avatar}
                alt={sub.name}
                width={24}
                height={24}
                className="rounded-full"
              />
              <span className="flex-1 truncate">{sub.name}</span>
              {sub.new && (
                <div className="flex h-[24px] w-[24px]">
                  <div className="m-auto h-sm w-sm rounded-full bg-purple-100"></div>
                </div>
              )}
            </Link>
          ))}
      </div>

      <div className="space-y-sm">
        <div className="flex justify-between px-md py-sm">
          <h2 className="font-semibold text-slate-100">Categories</h2>
          <button>
            <MoreIcon />
          </button>
        </div>
        {categories &&
          categories.map((cat, index) => (
            <>
              <CategoryListItem
                key={index}
                category={cat}
                depth={0}
                handleCategoryClick={handleCategoryClick}
                selectedCategories={selectedCategories}
              />
            </>
          ))}
      </div>
    </aside>
  );
}
