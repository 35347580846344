import Alert from "../../components/alert";

export default function Success() {

  return (
    <div className="mx-auto w-full max-w-3xl space-y-lg px-md py-lg">
      <h1 className="text-xl">Success!</h1>
      <Alert colour="success" text="Payment successful! You will receive an email confirmation. It may take a few moments for your purchase to be attributed. If this process hasn't completed within 5 minutes, please contact us." />
      </div>
  );
}
