interface Props {
  children: React.ReactNode;
}

export default function Table({ children }: Props) {
  return (
    <div className="divide-y divide-slate-200 rounded-md bg-slate-400 p-md">
      {children}
    </div>
  );
}
