import { Outlet } from "react-router-dom";
import Box from "../components/box";

const GenericLayout = () => {
  return (
    <Box size="lg">
      <Outlet />
    </Box>
  );
};

export default GenericLayout;
