import { useEffect, useState } from "react";
import AddCategory from "../../components/addcategory";
import Button from "../../components/button";
import Checkbox from "../../components/checkbox";
import Popup from "../../components/popup";
import { Category } from "../../types/Category";
import { Api } from "../../services/Api";
import { platformEvents } from "../../services/eventbus/platformEvents";

interface CategoryEntryProps {
  category: Category;
  path: string;
  selected: boolean;
  selected_categories: number[];
  setSelectedCategories: (categories: number[]) => void;
}

const CategoryEntry = ({
  category,
  path,
  selected,
  selected_categories,
  setSelectedCategories,
}: CategoryEntryProps) => {
  return (
    <div className="space-y-md">
      <div className="flex gap-md rounded-md bg-slate-400 p-md">
        <Checkbox
          disabled={
            category.all_children.length > 0 || category.content_count > 0
          }
          disabled_reason="Category contains content or children"
          selected={selected}
          id={category.id.toString()}
          onChange={(isSelected: boolean) => {
            if (isSelected) {
              setSelectedCategories([...selected_categories, category.id]);
            } else {
              setSelectedCategories(
                selected_categories.filter((id) => id !== category.id),
              );
            }
          }}
        />
        <span>{path}</span>
      </div>

      {category.all_children.map((subcategory, index) => (
        <CategoryEntry
          key={index}
          category={subcategory}
          path={path + " / " + subcategory.name}
          selected_categories={selected_categories}
          selected={selected_categories.includes(subcategory.id)}
          setSelectedCategories={setSelectedCategories}
        />
      ))}
    </div>
  );
};
export default function Categories() {
  const [addCategoryIsOpen, setAddCategoryIsOpen] = useState(false);
  const [categories, setCategories] = useState<Category[]>([]);
  const [selectedCategories, setSelectedCategories] = useState<number[]>([]);

  const toggleAddCategory = () => {
    setAddCategoryIsOpen(!addCategoryIsOpen);
  };

  const handleDeleteCategories = () => {
    if (selectedCategories.length > 0) {
      Api("/admins/categories", {
        method: "DELETE",
        data: { category_ids: selectedCategories },
      })
        .then(() => {
          platformEvents.emit(
            "generalSuccess",
            "The selected categories were deleted successfully",
          );
          Api("/browse/categories").then((data) => {
            setCategories(data);
          });
          setSelectedCategories([]);
        })
        .catch((data) => {
          platformEvents.emit(
            "generalFailure",
            "Something went wrong whilst deleting the selected categories. Please check and try again.",
          );
          Api("/browse/categories").then((data) => {
            setCategories(data);
          });
          setSelectedCategories([]);
        });
    }
  };

  useEffect(() => {
    Api("/browse/categories").then((data) => {
      setCategories(data);
    });
  }, []);

  useEffect(() => {
    const unsubscribeSuccess = platformEvents.on("generalSuccess", (_) => {
      Api("/browse/categories").then((data) => {
        setCategories(data);
      });
    });

    const unsubscribeFailed = platformEvents.on("generalFailure", (_) => {
      Api("/browse/categories").then((data) => {
        setCategories(data);
      });
    });

    return () => {
      unsubscribeSuccess();
      unsubscribeFailed();
    };
  }, []);

  return (
    <>
      <div className="flex-1 space-y-md p-md">
        <div className="flex gap-sm">
          <Button onClick={toggleAddCategory}>Add</Button>
          <Button onClick={handleDeleteCategories}>Delete</Button>
        </div>

        {categories.map((category, index) => (
          <CategoryEntry
            key={index}
            category={category}
            path={category.name}
            selected_categories={selectedCategories}
            selected={selectedCategories.includes(category.id)}
            setSelectedCategories={setSelectedCategories}
          />
        ))}
      </div>

      {addCategoryIsOpen && (
        <Popup>
          <AddCategory categories={categories} onClose={toggleAddCategory} />
        </Popup>
      )}
    </>
  );
}
