import CheckIcon from "../icons/check";
import { on } from "events";

interface Props {
  id: string;
  selected: boolean;
  disabled?: boolean;
  disabled_reason?: string;
  onChange?: (value: boolean) => void;
}

export default function Checkbox({
  id,
  selected,
  disabled,
  disabled_reason,
  onChange,
}: Props) {
  const handleCheckboxChange = () => {
    if (onChange) onChange(!selected);
  };

  return (
    <label className="cursor-pointer">
      <input
        disabled={disabled}
        type="checkbox"
        id={id}
        className="hidden"
        checked={selected}
        onChange={handleCheckboxChange}
      />

      <span
        className={`block h-[24px] w-[24px] rounded-sm border ${
          selected
            ? "border-purple-300 bg-purple-300"
            : "border-slate-200 bg-slate-300"
        } ${disabled ? "cursor-not-allowed" : "cursor-pointer"}`}
      >
        {selected && <CheckIcon />}
      </span>
    </label>
  );
}
