import { useState, useCallback, useEffect, useRef } from "react";
import Button from "../../components/button";
import EditContent from "../../components/editcontent";
import ManageCard from "../../components/managecard";
import { ManagedContentProps } from "../../components/managecard";
import Popup from "../../components/popup";
import { Api } from "../../services/Api";
import { useSanctum } from "react-sanctum";
import { useLocation } from "react-router-dom";
import { forEach } from "lodash";
import { platformEvents } from "../../services/eventbus/platformEvents";

export default function Content() {
  const [contentId, setContentId] = useState<number|undefined>(undefined);
  const [editContentIsOpen, setEditContentIsOpen] = useState(false);
  const { user, authenticated } = useSanctum();
  const [loading, setLoading] = useState<boolean>(false);
  const [page, setPage] = useState<number>(0);
  const [maxPages, setMaxPages] = useState<number>(0);
  const [content, setContent] = useState<ManagedContentProps[]>([]);
  const [selectedContent, setSelectedContent] = useState<number[]>([]);
  const sentinelRef = useRef(null);
  const location = useLocation();

  const toggleEditContent = (contentId?: number) => {
    setContentId(contentId);
    setEditContentIsOpen(!editContentIsOpen);
  };

  const handleMakePublic = (content_id: number) => {
    Api("/users/content/" + content_id + "/make-public", {
      method: "PATCH",
      data: {}
    })
    .then(() => {
      platformEvents.emit(
        "generalSuccess",
        "The selected content was updated successfully",
      );
      setSelectedContent([]);
      refreshContent(true);
    })
  }

  const handleStatusUpdate = (status: "approved" | "denied") => {
    if (selectedContent.length > 0) {
      Api("/" + user?.type + "/content", {
        method: "PATCH",
        data: {
          ids: selectedContent,
          status: status,
        },
      })
        .then(() => {
          platformEvents.emit(
            "generalSuccess",
            "The selected content was updated successfully",
          );
          setSelectedContent([]);
          refreshContent(true);
        })
        .catch((data) => {
          if (data.message) {
            platformEvents.emit("generalFailure", data.message);
          } else if (data.error) {
            platformEvents.emit("generalFailure", data.error);
          }
        });
    }
  };
  const handleArchive = () => {
    if (selectedContent.length > 0) {
      Api("/" + user?.type + "/content", {
        method: "PATCH",
        data: {
          ids: selectedContent,
          status: "archived",
        },
      })
        .then(() => {
          platformEvents.emit(
            "generalSuccess",
            "The selected content was archived successfully",
          );
          setSelectedContent([]);
          refreshContent(true);
        })
        .catch((data) => {
          if (data.message) {
            platformEvents.emit("generalFailure", data.message);
          } else if (data.error) {
            platformEvents.emit("generalFailure", data.error);
          }
        });
    }
  };

  const handleDelete = () => {
    if (selectedContent.length > 0) {
      Api("/" + user?.type + "/content", {
        method: "DELETE",
        data: {
          ids: selectedContent,
        },
      })
        .then(() => {
          platformEvents.emit(
            "generalSuccess",
            "The selected content was deleted successfully",
          );
          setSelectedContent([]);
          refreshContent(true);
        })
        .catch((data) => {
          if (data.message) {
            platformEvents.emit("generalFailure", data.message);
          } else if (data.error) {
            platformEvents.emit("generalFailure", data.error);
          }
        });
    }
  };

  const refreshContent = useCallback(
    (clear = true) => {
      setLoading(true);
      let newContent = content;
      let current_page = page;
      current_page = clear ? 1 : page + 1;
      newContent = clear ? [] : newContent;
      setPage(current_page);
      const params = new URLSearchParams({
        page: current_page.toString(),
      });
      Api(`/${user?.type}/content?${params.toString()}`)
        .then((data) => {
          forEach(data.data, (item) => {
            newContent.push({
              content_id: item.id,
              is_private: item.is_private,
              image: item.thumbnails[0] || "/images/placeholder.png",
              title: item.description.title,
              status: item.status,
              created_at: new Date(item.created_at),
              price_inc_tax_as_currency: item.price_inc_tax_as_currency,
              likes: item.like_count || 0,
              selected_content: selectedContent,
              setSelectedContent: setSelectedContent,
              selected: selectedContent.includes(item.id),
              disabled: false,
              disabled_reason: "",
              owners: item.owner_count,
              editOnClick: () => toggleEditContent(item.id),
              publicOnClick: () => handleMakePublic(item.id),
            });
          });
          if (data.data !== undefined) setContent(newContent);
          if (data.last_page) setMaxPages(data.last_page);
          if (data.current_page) setPage(data.current_page);
        })
        .finally(() => {
          setLoading(false);
        });
    },
    [content, page, selectedContent, user],
  );

  useEffect(() => {
    if (!authenticated) return;
    if (!editContentIsOpen)
      refreshContent(true)
    else
      refreshContent();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [authenticated, editContentIsOpen]);

  useEffect(() => {
    const options = {
      root: null,
      rootMargin: "0px",
      threshold: 0.1,
    };

    const observer = new IntersectionObserver(([entry]) => {
      if (entry.isIntersecting && page > 0 && page < maxPages && !loading) {
        if (!location.pathname.startsWith("/view")) {
          refreshContent(false);
        }
      }
    }, options);

    if (sentinelRef.current) {
      observer.observe(sentinelRef.current);
    }

    return () => observer.disconnect();
  }, [refreshContent, page, maxPages, loading, location]);

  return (
    <>
      <div className="flex-1 space-y-md p-md">
        <div className="flex gap-sm">
          {user?.type === "admins" && (
            <>
              <Button onClick={() => handleStatusUpdate("approved")}>
                Approve
              </Button>
              <Button onClick={() => handleStatusUpdate("denied")}>
                Decline
              </Button>
            </>
          )}
          <Button onClick={handleArchive}>Archive</Button>
          <Button onClick={handleDelete}>Delete</Button>
        </div>
        <div className="grid gap-md md:grid-cols-2 lg:grid-cols-1">
          {content.map((content, index) => (
            <ManageCard
              key={index}
              content_id={content.content_id}
              is_private={content.is_private}
              image={content.image}
              title={content.title}
              created_at={content.created_at}
              price_inc_tax_as_currency={content.price_inc_tax_as_currency}
              likes={content.likes}
              status={content.status}
              selected_content={selectedContent}
              disabled={false}
              disabled_reason="Content is owned by one or more users"
              selected={selectedContent.includes(content.content_id)}
              setSelectedContent={setSelectedContent}
              owners={content.owners}
              editOnClick={() => toggleEditContent(content.content_id)}
              publicOnClick={() => handleMakePublic(content.content_id)}
            />
          ))}
        </div>
        <div
          ref={sentinelRef}
          style={{ height: "10px" }}
          className="bg-transparent mb-lg mt-lg flex items-center justify-center font-semibold text-slate-100"
        >
          {page === maxPages ? "You've reached the end!" : null}
        </div>
      </div>
      {editContentIsOpen && (
        <Popup>
          <EditContent contentId={contentId} onClose={toggleEditContent} />
        </Popup>
      )}
    </>
  );
}
