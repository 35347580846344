import MarzipanoView from "../../components/marzipano-view"
import { HotspotSpec } from "../../services/useMarzipano"
import { SystemHotspot } from '../../types/SystemHotspot';
import { useEffect, useState } from "react"
import _ from "lodash";
import { Content } from "../../types/Content";
import { useParams } from "react-router-dom";
import { Api } from "../../services/Api";
import { useSanctum } from "react-sanctum";

export default function HotspotEditor() {
  const { id } = useParams();
  const { user, authenticated } = useSanctum();
  const [content, setContent] = useState<Content>()
  const [systemHotspots, setSystemHotspots] = useState<SystemHotspot[]>([]);

  useEffect(() => {
    if (!authenticated) return;
    Api(`/${user?.type}/content/${id}`).then((res) => {
      setContent(res);
      Api(`/content/${id}/hotspots`).then((hotspots) => {
        setSystemHotspots(hotspots);
      });
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [authenticated]);

  if (!content) return null;

  let scene = new Map([
    ["1", {
      isCurrent: true,
      key: "1",
      hotspots: new Map<string, HotspotSpec>([]),
      imageUrl: content.file_type === "image" ? content.file_url : '',
      movieUrl: content.file_type === "video" ? content.file_url : '',
      type: 'equirect'
    }],
  ])

  return (
      <MarzipanoView content={content} initialHotspots={systemHotspots} scene={scene} />
  )
}