import flattenDeep from "lodash/flattenDeep";
import { Route, Routes as ReactRoutes } from "react-router-dom";

const generateFlattenRoutes = (routes: any[]): any[] => {
  if (!routes) return [];
  return flattenDeep(
    routes.map(
      ({
        routes: subRoutes,
        ...rest
      }: {
        routes: any[];
        [key: string]: any;
      }) => [rest, generateFlattenRoutes(subRoutes)],
    ),
  );
};

export const renderRoutes = async (mainRoutes: any[]) => {
  const user = await fetch(
    (process.env.REACT_APP_API_ENDPOINT || "http://localhost/api") +
      "/auth/user",
    {
      headers: { Accept: "application/json" },
      credentials: "include",
    },
  ).then((response) => response.json());
  const Routes = () => {
    const layouts = mainRoutes.map(({ layout: Layout, routes }, index) => {
      const subRoutes = generateFlattenRoutes(routes);

      return (
        <Route key={index} element={<Layout />}>
          {subRoutes.map(({ component: Component, path, name, user_types }) => {
            return (
              Component &&
              path &&
              (!user_types || user_types.includes(user.type)) && (
                <Route key={name} element={<Component />} path={path} />
              )
            );
          })}
        </Route>
      );
    });
    return <ReactRoutes>{layouts}</ReactRoutes>;
  };
  return Routes;
};
