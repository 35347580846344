import Button from "./button";
import Box from "./box";
import Input from "./input";
import Textarea from "./textarea";
import { useEffect, useState } from "react";
import { Api } from "../services/Api";
import { platformEvents } from "../services/eventbus/platformEvents";
import { useSanctum } from "react-sanctum";

interface Props {
  contentId?: number;
  onClose: (message?: string) => void;
}

export default function EditContent({contentId, onClose }: Props) {
  const [title, setTitle] = useState<string>('');
  const [titleLength, setTitleLength] = useState(0);
  const [description, setDescription] = useState<string>('');
  const [descriptionLength, setDescriptionLength] = useState(0);
  const [price, setPrice] = useState<number>(0);
  const { user, authenticated } = useSanctum();
  const titleMaxLength = 200;
  const descriptionMaxLength = 600;

  const handleSubmit = () => {
    Api("/" + user?.type + "/content/" + contentId, {
      method: "PATCH",
      data: {
        title: title,
        description: description,
        price: price
      }
    })
    .then((data) => {
      platformEvents.emit("generalSuccess", "Content updated successfully!");
      onClose();
    })
    .catch((data) => {
      if (data.message) {
        platformEvents.emit("generalFailure", data.message);
      } else if (data.error) {
        platformEvents.emit("generalFailure", data.error);
      }
    });
  }

  useEffect(() => {
    Api("/" + user?.type + "/content/" + contentId).then((data) => {
      setTitle(data.title);
      setTitleLength(data.title.length);
      setDescription(data.description);
      setDescriptionLength(data.description.length);
      setPrice(data.price);
    })
  }, []);

  return (
    <Box size="xl">
      <div className="space-y-lg">
        <h2 className="text-center text-xl">Edit Content</h2>

        <div className="space-y-sm">
          <div className="flex justify-between">
            <div className="flex items-center gap-sm">
              <span className="h-xs w-xs rounded-full bg-purple-100"></span>
              <label htmlFor="title">Title (required)</label>
            </div>
            <span className="text-slate-100">{titleLength}/{titleMaxLength}</span>
          </div>
          <Input
            type="text"
            id="title"
            placeholder="Title"
            value={title}
            onChange={(e) => {
              setTitle(e.target.value);
              setTitleLength(e.target.value.length);
            }}
            maxLength={200}
          />
        </div>

        <div className="space-y-sm">
          <div className="flex justify-between">
            <div className="flex items-center gap-sm">
              <span className="h-xs w-xs rounded-full bg-purple-100"></span>
              <label htmlFor="description">Description (required)</label>
            </div>
            <span className="text-slate-100">{descriptionLength}/{descriptionMaxLength}</span>
          </div>
          <Textarea
            id="description"
            placeholder="Description"
            rows={5}
            minLength={1}
            maxLength={600}
            value={description}
            onChange={(e) => {
              setDescription(e.target.value);
              setDescriptionLength(e.target.value.length);
            }}
          />
        </div>

        { user?.type !== 'users' && (
          <div className="space-y-sm">
            <div className="flex items-center gap-sm">
              <span className="h-xs w-xs rounded-full bg-purple-100"></span>
              <label htmlFor="price">Price (required)</label>
            </div>
            <Input type="number" id="price" placeholder="Price"
              onChange={(e) => {
                setPrice(+e.target.value);
              }}
              value={price.toString()} />
          </div>
        )}

        <div className="flex justify-between border-t border-slate-200 pt-lg">
          <Button onClick={onClose}>Cancel</Button>
          <Button primary onClick={handleSubmit}>Save</Button>
        </div>
      </div>
    </Box>
  );
}
