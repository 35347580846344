import Button from "./button";
import Box from "./box";
import Input from "./input";
import { Api } from "../services/Api";
import Alert from "./alert";
import { platformEvents } from "../services/eventbus/platformEvents";
import { useState } from "react";

interface Props {
  onClose: (message?: string) => void;
}

export default function AddTag({ onClose }: Props) {
  const [tag, setTag] = useState("");
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);

  const handleAddTag = () => {
    if (tag !== "") {
      setLoading(true);
      Api("/admins/tags", {
        method: "POST",
        data: {
          tag: tag,
        },
      })
        .then((data) => {
          platformEvents.emit("generalSuccess", "Tag added successfully!");
          setLoading(false);
          setTag("");
          onClose();
        })
        .catch((data) => {
          if (data.error) {
            setError(data.error);
          } else if (data.message) {
            setError(data.message);
          }
          setLoading(false);
        });
    }
  };

  return (
    <Box size="xl">
      <div className="space-y-lg">
        <h2 className="text-center text-xl">Add Category</h2>

        <div className="space-y-lg">
          {error !== "" && <Alert text={error} colour="danger" />}

          <div className="space-y-sm">
            <label htmlFor="name" className="block">
              Tag Name
            </label>
            <Input
              type="text"
              id="name"
              name="name"
              placeholder="Tag name"
              required
              value={tag}
              onChange={(e) => setTag(e.target.value)}
            />
          </div>
        </div>

        <div className="flex justify-between border-t border-slate-200 pt-lg">
          <Button onClick={onClose} disabled={loading}>
            Cancel
          </Button>
          <Button
            onClick={handleAddTag}
            disabled={tag === "" || loading}
            primary
          >
            Add
          </Button>
        </div>
      </div>
    </Box>
  );
}
