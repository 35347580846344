const Like = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} fill="none">
    <path
      stroke="currentcolor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.5}
      d="M18.489 12.511 12 19l-6.489-6.489a3.889 3.889 0 1 1 5.5-5.5L12 8l.989-.989a3.889 3.889 0 1 1 5.5 5.5Z"
    />
  </svg>
);
export default Like;
