import React, { useState } from "react";
import { useSanctum } from "react-sanctum";
import { Link, useLocation, useNavigate } from "react-router-dom";
import Alert from "../../components/alert";
import Button from "../../components/button";
import Input from "../../components/input";

export default function Login() {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const { signIn } = useSanctum();
  const location = useLocation();

  const handleLogin = async (event: React.FormEvent) => {
    event.preventDefault();
    const formData = new FormData(event.target as HTMLFormElement);
    setLoading(true);
    signIn(
      formData.get("username") as string,
      formData.get("password") as string,
    )
      .then(() => {
        const params = new URLSearchParams(location.search);
        window.location.href = params.get("p") || "/browse";
      })
      .catch((axios) => {
        if (axios.response.data.error) {
          setError(axios.response.data.error);
        } else if (axios.response.data.message) {
          setError(axios.response.data.message);
        }
        setLoading(false);
      });
  };

  return (
    <div className="space-y-lg">
      <div className="space-y-sm text-center">
        <h1 className="text-xl">Welcome back!</h1>
        <p className="text-slate-100">Please enter your details.</p>
      </div>

      <form onSubmit={handleLogin} method="POST" className="space-y-lg">
        {error ? <Alert text={error} colour="danger" /> : null}

        <div className="space-y-sm">
          <label htmlFor="email" className="block">
            Username
          </label>
          <Input
            type="text"
            id="username"
            name="username"
            placeholder="Your username"
            tabindex={1}
            required
            disabled={loading}
          />
        </div>

        <div className="space-y-sm">
          <div className="flex justify-between">
            <label htmlFor="password" className="block">
              Password
            </label>
            <Link to="/forgot" className="text-purple-100">
              Forgot password?
            </Link>
          </div>
          <Input
            type="password"
            id="password"
            name="password"
            placeholder="Password"
            tabindex={2}
            required
            disabled={loading}
          />
        </div>

        <Button block primary disabled={loading}>
          {loading ? "Loading..." : "Sign in"}
        </Button>

        <div className="border-t border-slate-200 pt-lg text-center">
          <span className="text-slate-100">Don't have an account? </span>
          <Link to="/register" className="text-purple-100 underline">
            Sign up
          </Link>
        </div>
      </form>
    </div>
  );
}
