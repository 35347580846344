import { useEffect, useState } from "react";
import { useSanctum } from "react-sanctum";
import Button from "../../components/button";
import Input from "../../components/input";
import { Api } from "../../services/Api";
import { platformEvents } from "../../services/eventbus/platformEvents";
import Select from "../../components/select";

export default function Account() {
  const [loading, setLoading] = useState(false);
  const { user, setUser } = useSanctum();

  const [name, setName] = useState<string>("");
  const [email, setEmail] = useState<string>("");
  const [username, setUsername] = useState<string>("");
  const [password, setPassword] = useState<string>("");
  const [password_confirmation, setPasswordConfirmation] = useState<string>("");
  const [addressLine1, setAddressLine1] = useState<string>("");
  const [addressLine2, setAddressLine2] = useState<string>("");
  const [addressLine3, setAddressLine3] = useState<string>("");
  const [city, setCity] = useState<string>("");
  const [postcode, setPostcode] = useState<string>("");
  const [stateId, setStateId] = useState<number | null>(null);
  const [countryId, setCountryId] = useState<number | null>(null);

  const [states, setStates] = useState<{ id: number; name: string }[]>([]);
  const [countries, setCountries] = useState<{ id: number; name: string }[]>(
    [],
  );

  const handleSubmit = () => {
    setLoading(true);
    Api("/auth/user", {
      method: "PATCH",
      data: {
        email: email,
        name: name,
        password: password,
        password_confirmation: password_confirmation,
        addressLine1: addressLine1,
        addressLine2: addressLine2,
        addressLine3: addressLine3,
        city: city,
        postcode: postcode,
        stateId: stateId,
        countryId: countryId,
      },
    })
      .then((data) => {
        platformEvents.emit("generalSuccess", "Account updated successfully");
        setPassword("");
        setPasswordConfirmation("");
        Api("/auth/user").then((data) => {
          setUser(data, true);
        })
        setLoading(false);
      })
      .catch((data) => {
        if (data.message) {
          platformEvents.emit("generalFailure", data.message);
        } else if (data.error) {
          platformEvents.emit("generalFailure", data.error);
        }
        setLoading(false);
      });
  };

  useEffect(() => {
    // Reload states and set state ID to null
    if (countryId) {
      Api("/countries/" + countryId + "/states")
        .then((data) => {
          setStates(data);
        })
        .catch((data) => {
          if (data.message) {
            platformEvents.emit("generalFailure", data.message);
          } else if (data.error) {
            platformEvents.emit("generalFailure", data.error);
          }
        });
    }
  }, [countryId]);

  useEffect(() => {
    Api("/countries")
      .then((data) => {
        setCountries(data);
      })
      .catch((data) => {
        if (data.message) {
          platformEvents.emit("generalFailure", data.message);
        } else if (data.error) {
          platformEvents.emit("generalFailure", data.error);
        }
      });
    if (user?.user) {
      setName(user.user.name);
      setEmail(user.user.email);
      setUsername(user.user.username);
      if (user.user.primary_billing_address) {
        setAddressLine1(user.user.primary_billing_address.line_1 || "");
        setAddressLine2(user.user.primary_billing_address.line_2 || "");
        setAddressLine3(user.user.primary_billing_address.line_3 || "");
        setCity(user.user.primary_billing_address.city || "");
        setPostcode(user.user.primary_billing_address.postal_code || "");
        setStateId(user.user.primary_billing_address.state_id);
        setCountryId(user.user.primary_billing_address.country_id);
      }
    }
  }, [user]);

  return (
    <div className="mx-auto w-full max-w-3xl space-y-lg px-md py-lg">
      <h1 className="text-xl">Account</h1>
      <div className="space-y-lg">
        <div className="space-y-sm">
          <label htmlFor="name" className="block">
            Name
          </label>
          <Input
            type="text"
            id="name"
            placeholder="Your full name"
            value={name}
            onChange={(e) => setName(e.target.value)}
          />
        </div>

        <div className="space-y-sm">
          <label htmlFor="username" className="block">
            Username
          </label>
          <Input type="text" id="username" value={username} disabled />
        </div>

        <div className="space-y-sm">
          <label htmlFor="email" className="block">
            Email
          </label>
          <Input
            type="email"
            id="email"
            name="email"
            placeholder="example@domain.com"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
        </div>

        <div className="space-y-sm">
          <label htmlFor="password" className="block">
            Password
          </label>
          <Input
            type="password"
            id="password"
            name="password"
            placeholder="Password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
        </div>

        <div className="space-y-sm">
          <label htmlFor="confirm-password" className="block">
            Confirm Password
          </label>
          <Input
            type="password"
            id="confirm-password"
            name="password_confirmation"
            placeholder="Confirm Password"
            value={password_confirmation}
            onChange={(e) => setPasswordConfirmation(e.target.value)}
          />
        </div>

        <div className="space-y-sm">
          <label htmlFor="address-line-1" className="block">
            Address Line 1
          </label>
          <Input
            type="text"
            id="address-line-1"
            placeholder="Address Line 1"
            value={addressLine1}
            onChange={(e) => setAddressLine1(e.target.value)}
          />
        </div>

        <div className="space-y-sm">
          <label htmlFor="address-line-2" className="block">
            Address Line 2
          </label>
          <Input
            type="text"
            id="address-line-2"
            placeholder="Address Line 2"
            value={addressLine2}
            onChange={(e) => setAddressLine2(e.target.value)}
          />
        </div>

        <div className="space-y-sm">
          <label htmlFor="address-line-3" className="block">
            Address Line 3
          </label>
          <Input
            type="text"
            id="address-line-3"
            placeholder="Address Line 3"
            value={addressLine3}
            onChange={(e) => setAddressLine3(e.target.value)}
          />
        </div>

        <div className="space-y-sm">
          <label htmlFor="city" className="block">
            City
          </label>
          <Input
            type="text"
            id="city"
            placeholder="City"
            value={city}
            onChange={(e) => setCity(e.target.value)}
          />
        </div>

        <div className="space-y-sm">
          <label htmlFor="postcode" className="block">
            Postal Code
          </label>
          <Input
            type="text"
            id="postcode"
            placeholder="Postal Code"
            value={postcode}
            onChange={(e) => setPostcode(e.target.value)}
          />
        </div>

        <div className="space-y-sm">
          <label htmlFor="country" className="block">
            Country
          </label>
          <Select
            options={countries.map((country) => country.name.toString())}
            value={
              countryId
                ? countries.find((c) => c.id === countryId)?.name || ""
                : ""
            }
            valueChanged={(value) => {
              const country = countries.find((c) => c.name === value);
              setStateId(null);
              setCountryId(country?.id || null);
            }}
          />
        </div>

        <div className="space-y-sm">
          <label htmlFor="state" className="block">
            State/County
          </label>
          <Select
            options={states.map((country) => country.name.toString())}
            value={
              stateId ? states.find((c) => c.id === stateId)?.name || "" : ""
            }
            valueChanged={(value) => {
              const state = states.find((c) => c.name === value);
              setStateId(state?.id || null);
            }}
          />
        </div>

        <Button onClick={handleSubmit} primary disabled={loading}>
          {loading ? "Loading..." : "Save"}
        </Button>
      </div>
    </div>
  );
}
