import React, { useRef, useState, useEffect } from "react";
import {
  Outlet,
  useLocation,
  useNavigate,
  useOutletContext,
} from "react-router-dom";
import { Api } from "../services/Api";
import Sidebar from "../components/sidebar";
import Header from "../components/header";
import Footer from "../components/footer";
import { Category } from "../types/Category";
import { Subscription } from "../types/Subscription";
import useWindowSize from "../services/useWindowSize";
import { useSanctum } from "react-sanctum";
import Toaster from "../components/toaster";
import { forEach } from "lodash";
import { platformEvents } from "../services/eventbus/platformEvents";

type ContextType = {
  user: any;
  sort: string;
  setSort: (sort: string) => void;
  sortOptions: string[];
  categories: Category[];
  selectedCategories: Category[];
  setSelectedCategories: (categories: Category[]) => void;
  subscriptions: Subscription[];
  searchQuery: string;
  loading: boolean;
  setLoading: (loading: boolean) => void;
};

const BrowseLayout = () => {
  const [loading, setLoading] = useState<boolean>(false);
  const toasterRef = useRef<any>();
  const location = useLocation();
  const navigate = useNavigate();
  const { user, checkAuthentication, authenticated } = useSanctum();
  const windowSize = useWindowSize();
  const [sidebarOpen, setSidebarOpen] = useState<boolean>(false);

  const [categories, setCategories] = useState<Category[] | any>([]);
  const [selectedCategories, setSelectedCategories] = useState<
    Category[] | any
  >([]);

  const [subscriptions, setSubscriptions] = useState<Subscription[] | any>([]);

  const sortOptions = ["Date", "Popularity", "Rating"];
  const [sort, setSort] = useState<string>(() => {
    if (location.pathname === "/latest") return "Date";
    if (location.pathname === "/popular") return "Popularity";
    return sortOptions[0];
  });

  const [searchQuery, setSearchQuery] = useState<string>("");

  // CATEGORY CLICK
  const handleCategoryClick = (category: Category) => {
    if (selectedCategories.includes(category)) {
      setSelectedCategories(
        selectedCategories.filter((c: Category) => c.id !== category.id),
      );
    } else {
      setSelectedCategories([...selectedCategories, category]);
    }
  };

  // REMOVE CATEGORY
  const handleCategoryRemove = (category: Category) => {
    setSelectedCategories(
      selectedCategories.filter((c: Category) => c.id !== category.id),
    );
  };

  // REFRESH CONTENT

  // TOGGLE SIDEBAR
  const toggleSidebar = () => {
    setSidebarOpen(!sidebarOpen);
  };

  // CLOSE SIDEBAR ON SMALL SCREENS
  const closeSidebar = () => {
    if (windowSize.width && windowSize.width < 1280) {
      setSidebarOpen(false);
    }
  };

  const refreshSubscriptions = () => {
    Api("/browse/subscriptions").then((data) => {
      if (data) {
        let newSubs: Subscription[] = [];
        forEach(data, function (sub, index) {
          newSubs.push({
            id: sub.id,
            creator: sub,
            name: sub.name,
            new: false,
          });
        });
        setSubscriptions(newSubs);
      }
    });
  };

  // SET SIDEBAR OPEN ON LARGE SCREENS
  useEffect(() => {
    if (windowSize.width && windowSize.width > 1280) {
      setSidebarOpen(true);
    } else {
      setSidebarOpen(false);
    }
  }, [windowSize]);

  // INITIAL LOAD FOR CATEGORIES AND SUBSCRIPTIONS
  useEffect(() => {
    if (!authenticated) return;
    Api("/browse/categories").then((data) => {
      if (data) setCategories(data);
    });

    refreshSubscriptions();
  }, [authenticated]);

  useEffect(() => {
    async function checkAuth() {
      const authenticated = await checkAuthentication();
      if (!authenticated) {
        navigate("/login?p=" + encodeURIComponent(location.pathname));
      }
    }
    if (!authenticated) checkAuth();
  }, [checkAuthentication, location.pathname, navigate, authenticated]);

  useEffect(() => {
    const unsubscribeItemAdded = platformEvents.on("itemAddedToCart", () => {
      if (toasterRef.current) {
        toasterRef.current.addToast(
          "Content Added",
          "Your content was added to the cart successfully!",
          "success",
        );
      }
    });

    const unsubscribeItemRemoved = platformEvents.on(
      "itemRemovedFromCart",
      () => {
        if (toasterRef.current) {
          toasterRef.current.addToast(
            "Content Removed",
            "Your content was removed from the cart successfully!",
            "success",
          );
        }
      },
    );

    const unsubscribeSubscribedToCreator = platformEvents.on(
      "subscriptionAdded",
      refreshSubscriptions,
    );

    const unsubscribeUnsubscribedToCreator = platformEvents.on(
      "subscriptionRemoved",
      refreshSubscriptions,
    );

    return () => {
      unsubscribeItemAdded();
      unsubscribeItemRemoved();
      unsubscribeSubscribedToCreator();
      unsubscribeUnsubscribedToCreator();
    };
  }, []);

  useEffect(() => {
    if (location.pathname === "/latest") {
      setSort(() => "Date");
    }
    if (location.pathname === "/popular") {
      setSort(() => "Popularity");
    }
  }, [location.pathname]);

  if (!user) return null;

  return (
    <>
      {sidebarOpen ? (
        <Sidebar
          categories={categories}
          subscriptions={subscriptions}
          selectedCategories={selectedCategories}
          handleSubscriptionClick={() => {}}
          handleCategoryClick={handleCategoryClick}
          handleCategoryRemove={handleCategoryRemove}
          outsideClicked={closeSidebar}
        />
      ) : null}
      <div className="custom-scrollbar flex flex-1 flex-col overflow-y-auto">
        <Header
          user={user}
          searchQuery={searchQuery}
          menuButtonClicked={toggleSidebar}
          searchCallback={setSearchQuery}
        />
        <Outlet
          context={
            {
              user,
              sort,
              setSort,
              sortOptions,
              categories,
              selectedCategories,
              setSelectedCategories,
              subscriptions,
              searchQuery: searchQuery,
              loading: loading,
              setLoading: setLoading,
            } satisfies ContextType
          }
        />
        <Footer />
      </div>
      <Toaster ref={toasterRef} timeout={5000} />
    </>
  );
};

export function useBrowseLayoutContext() {
  return useOutletContext<ContextType>();
}

export default BrowseLayout;
