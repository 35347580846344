interface Props {
  text: string;
  colour: "primary" | "secondary" | "success" | "danger";
  onClick?: (text: string) => void;
}

export default function Chip({ text, colour, onClick }: Props) {
  let colourClass = "";

  switch (colour) {
    case "primary":
      colourClass = "text-slate-100 border-slate-200";
      break;
    case "secondary":
      colourClass = "text-purple-100 border-purple-200";
      break;
    case "success":
      colourClass = "text-emerald-100 border-emerald-200";
      break;
    case "danger":
      colourClass = "text-amber-100 border-amber-200";
      break;
  }

  return (
    <span
      onClick={() => {
        onClick?.(text);
      }}
      className={`rounded-md border px-sm py-xs ${colourClass} ${
        onClick ? "cursor-pointer" : ""
      }`}
    >
      {text}
    </span>
  );
}
