import { useState, useEffect, useRef } from "react";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import AccountSidebar from "../components/accountsidebar";
import Header from "../components/header";
import Footer from "../components/footer";
import useWindowSize from "../services/useWindowSize";
import { useSanctum } from "react-sanctum";
import Toaster from "../components/toaster";
import { platformEvents } from "../services/eventbus/platformEvents";

const AccountLayout = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const toasterRef = useRef<any>();
  const { user, checkAuthentication, authenticated } = useSanctum();
  const windowSize = useWindowSize();
  const [sidebarOpen, setSidebarOpen] = useState<boolean>(false);

  const toggleSidebar = () => {
    setSidebarOpen(!sidebarOpen);
  };

  const closeSidebar = () => {
    if (windowSize.width && windowSize.width < 1280) {
      setSidebarOpen(false);
    }
  };

  useEffect(() => {
    if (windowSize.width && windowSize.width > 1280) {
      setSidebarOpen(true);
    } else {
      setSidebarOpen(false);
    }
  }, [windowSize]);

  useEffect(() => {
    async function checkAuth() {
      const authenticated = await checkAuthentication();
      if (!authenticated) {
        navigate("/login?p=" + encodeURIComponent(location.pathname));
      }
    }
    if (!authenticated) checkAuth();
  }, [checkAuthentication, location.pathname, navigate, authenticated]);

  useEffect(() => {
    const unsubscribeSuccess = platformEvents.on(
      "generalSuccess",
      (message) => {
        if (toasterRef.current) {
          toasterRef.current.addToast("Success", message, "success");
        }
      },
    );

    const unsubscribeFailed = platformEvents.on("generalFailure", (message) => {
      if (toasterRef.current) {
        toasterRef.current.addToast("Failed", message, "danger");
      }
    });

    return () => {
      unsubscribeSuccess();
      unsubscribeFailed();
    };
  }, []);

  if (!user) return null;


  return (
    <>
      {sidebarOpen && !location.pathname.includes('/content/hotspots') ? <AccountSidebar outsideClicked={closeSidebar} /> : null}
      <div className="custom-scrollbar flex flex-1 flex-col overflow-y-auto">
        {!location.pathname.includes('/content/hotspots') ? <Header user={user} menuButtonClicked={toggleSidebar} /> : null}
        <Outlet />
        {!location.pathname.includes('/content/hotspots') ? <Footer /> : null}
      </div>
      <Toaster ref={toasterRef} timeout={5000} />
    </>
  );
};

export default AccountLayout;
