import { useNavigate } from "react-router-dom";
import Box from "./box";
import Button from "./button";
import { useCart } from "../services/CartContext";
import { useCallback, useEffect, useState } from "react";
import { loadStripe } from "@stripe/stripe-js";
import {
  PaymentElement,
  Elements,
  useStripe,
  useElements,
} from "@stripe/react-stripe-js";
import { StripeElementsOptions } from "@stripe/stripe-js";
import Alert from "./alert";
import { Api } from "../services/Api";
import { useSanctum } from "react-sanctum";
import Checkbox from "./checkbox";
import Input from "./input";

interface Props {
  onClose: () => void;
}

interface CheckoutProps {
  updateStep: (number: number) => void;
}

const CheckoutForm = ({ updateStep }: CheckoutProps) => {
  const stripe = useStripe();
  const elements = useElements();

  const [errorMessage, setErrorMessage] = useState<string | undefined | null>(
    null,
  );

  const handleSubmit = async (event: { preventDefault: () => void }) => {
    event.preventDefault();

    if (elements == null) {
      return;
    }

    const { error: submitError } = await elements.submit();
    if (submitError) {
      setErrorMessage(submitError.message);
      return;
    }

    const res = await Api("/cart/payment", {
      method: "POST",
      data: {
        payment_method: 'stripe'
      }
    });

    if (stripe !== null) {
      const { error } = await stripe.confirmPayment({
        elements,
        clientSecret: res.client_secret,
        confirmParams: {
          return_url: window.location.protocol + '//' + window.location.host + '/success'
        },
      });

      if (error) {
        setErrorMessage(error.message);
      } else {
        updateStep(4);
      }
    }
  };

  return (
    <>
      {errorMessage && errorMessage !== "" && (
        <Alert text={errorMessage} colour="danger" />
      )}
      <form onSubmit={handleSubmit}>
        <PaymentElement />
        {stripe !== null && elements !== null ? (
          <div className="pt-md">
            <Button primary>Pay</Button>
          </div>
        ) : (
          <Alert
            text="The payment processor is not available at the moment. Please try again later."
            colour="danger"
          />
        )}
      </form>
    </>
  );
};

export default function Cart({ onClose }: Props) {
  const {
    cart,
    sub_total_as_currency,
    tax_total_as_currency,
    total_as_currency,
    total,
    removeFromCart,
  } = useCart();

  const { user, setUser } = useSanctum();
  const [method, setMethod] = useState<"stripe" | "purchase_order" | "free">("stripe");
  const [currentStep, setCurrentStep] = useState(1);
  const [poNumber, setPoNumber] = useState("");
  const navigate = useNavigate();

  const getUser = useCallback(() => {
    Api("/auth/user").then((data) => {
      setUser(data);
    });
  }, [setUser]);

  const handleOrderPayment = () => {
    Api("/cart/payment", {
      method: "POST",
      data: {
        payment_method: method,
        purchase_order_number: poNumber
      }
    }).then((res) => {
      if (res.success) {
        setCurrentStep(4);
      }
    })
  };

  useEffect(() => {
    getUser();
    // Disabled lint due to recursive fetch
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (total === 0) {
      setMethod("free");
    } else {
      setMethod("stripe");
    }
  }, [total]);

  if (currentStep === 1) {
    return (
      <Box size="xl">
        <div className="space-y-lg">
          <h2 className="text-center text-xl">Shopping Cart</h2>
          {!user?.user.primary_billing_address?.country_id ||
          !user?.user.primary_billing_address?.state_id ? (
            <Alert
              colour="danger"
              text="You cannot checkout as you have not provided your billing address. Please update your billing address in your account before proceeding to checkout."
            />
          ) : (
            <>
              {user?.type !== "users" && (
                <Alert
                  colour="danger"
                  text="You cannot checkout because you are not the correct type of user."
                />
              )}
              {cart.length === 0 && user?.type === "users" ? (
                <p className="text-center">Your cart is empty</p>
              ) : (
                <>
                  {cart.map((lineItem, index) => (
                    <article key={index} className="flex items-center gap-md">
                      <img
                        src={
                          lineItem.content.thumbnails[0] ||
                          "/images/placeholder.png"
                        }
                        alt={lineItem.content.description.title}
                        width={96}
                        height={64}
                        className="rounded-md"
                      />
                      <div className="min-w-0 flex-1 space-y-sm">
                        <div className="flex gap-sm">
                          <h3 className="truncate">
                            {lineItem.content.description.title}
                          </h3>
                          <span
                            className="cursor-pointer select-none text-slate-100 underline"
                            onClick={() => {
                              onClose();
                              navigate("/view/" + lineItem.content.id);
                            }}
                          >
                            View
                          </span>
                          <button
                            onClick={() => removeFromCart(lineItem.id)}
                            className="text-purple-100 underline"
                          >
                            Remove
                          </button>
                        </div>
                        <div className="flex gap-sm text-slate-100">
                          <img
                            src={lineItem.content.creator.avatar}
                            alt={lineItem.content.creator.name}
                            width={24}
                            height={24}
                            className="rounded-full"
                          />
                          <span className="flex-1 truncate">
                            {lineItem.content.creator.name}
                          </span>
                          <span>{lineItem.total_as_currency}</span>
                        </div>
                      </div>
                    </article>
                  ))}

                  {user?.type === "users" && (
                    <>
                      <div className="space-y-md border-t border-slate-200 pt-lg">
                        <div className="flex justify-between">
                          <div>Subtotal</div>
                          <div className="text-slate-100">
                            {sub_total_as_currency}
                          </div>
                        </div>
                        <div className="flex justify-between">
                          <div>Taxes</div>
                          <div className="text-slate-100">
                            {tax_total_as_currency}
                          </div>
                        </div>
                        <div className="flex justify-between">
                          <div>Total</div>
                          <div className="text-slate-100">
                            {total_as_currency}
                          </div>
                        </div>
                      </div>
                      <div className="space-y-md border-t border-slate-200 pt-lg">
                        <h3 className="text-lg">Payment Method</h3>
                        { total == 0 ? (
                          <div className="space-y-sm">
                            <div className="flex flex-row">
                              <Checkbox
                                id="free"
                                selected={method === "free"}
                                onChange={() => setMethod("free")}
                              />
                              <span style={{ paddingLeft: "10px" }}>
                                Free Checkout
                              </span>
                            </div>
                          </div>
                        ) : (
                          <div className="space-y-sm">
                            <div className="flex flex-row">
                              <Checkbox
                                id="stripe"
                                selected={method === "stripe"}
                                onChange={() => setMethod("stripe")}
                              />
                              <span style={{ paddingLeft: "10px" }}>
                                Credit or Debit Card
                              </span>
                            </div>
                            <div className="flex flex-row">
                              <Checkbox
                                id="stripe"
                                selected={method === "purchase_order"}
                                onChange={() => setMethod("purchase_order")}
                              />
                              <span style={{ paddingLeft: "10px" }}>
                                Purchase Order
                              </span>
                            </div>
                          </div>
                        )}
                      </div>
                    </>
                  )}
                </>
              )}
            </>
          )}

          <div className="flex justify-between border-t border-slate-200 pt-lg">
            <Button onClick={onClose}>Close</Button>
            <Button
              primary
              disabled={
                !cart ||
                !user?.user.primary_billing_address?.country_id ||
                !user?.user.primary_billing_address?.state_id ||
                user?.type !== "users" ||
                (method !== "stripe" && method !== "purchase_order" && method !== "free")
              }
              onClick={() => {
                if (method === "stripe") {
                  setCurrentStep(2);
                } else if (method === "purchase_order") {
                  // Handle other purchase order
                  setCurrentStep(3);
                } else if (method === "free") {
                  // Handle free purchase
                  handleOrderPayment();
                }
              }}
            >
              Checkout
            </Button>
          </div>
        </div>
      </Box>
    );
  }

  // Stripe option
  if (currentStep === 2) {
    const stripePromise = loadStripe(
      process.env.REACT_APP_STRIPE_PK ||
        "pk_test_6FQIfvAgAbLk6TaPY0GRTEdu",
    );
    const options: StripeElementsOptions = {
      mode: "payment",
      amount: total,
      currency: "gbp",
      appearance: {
        theme: "night",
      },
    };
    return (
      <Box size="xl">
        <div className="space-y-lg">
          <h2 className="text-center text-xl">Shopping Cart</h2>
          <Elements stripe={stripePromise} options={options}>
            <CheckoutForm updateStep={setCurrentStep} />
          </Elements>
          <div className="flex justify-between border-t border-slate-200 pt-lg">
            <Button onClick={() => setCurrentStep(1)}>Back</Button>
          </div>
        </div>
      </Box>
    );
  }

  // Purchase order option
  if (currentStep === 3) {
    return (
      <Box size="xl">
        <div className="space-y-lg">
          <h2 className="text-center text-xl">Shopping Cart</h2>
          <div className="space-y-sm">
            <label htmlFor="name" className="block">
              Purchase Order Number
            </label>
            <Input
              type="text"
              id="po-number"
              name="po_number"
              placeholder="PO Number..."
              required
              value={poNumber}
              onChange={(e) => setPoNumber(e.target.value)}
            />
          </div>
          <Button onClick={handleOrderPayment}>Submit Purchase Order</Button>
          <div className="flex justify-between border-t border-slate-200 pt-lg">
            <Button onClick={() => setCurrentStep(1)}>Back</Button>
          </div>
        </div>
      </Box>
    );
  }
  if (currentStep === 4) {
    return (
      <Box size="xl">
        <div className="space-y-lg">
          <h2 className="text-center text-xl">Success</h2>
          <Alert
            colour="success"
            text="Payment successful! You will receive an email confirmation. It may take a few moments for your purchase to be attributed. If this process hasn't completed within 5 minutes, please contact us."
          />
          <div className="flex justify-between border-t border-slate-200 pt-lg">
            <Button onClick={onClose}>Close</Button>
          </div>
        </div>
      </Box>
    );
  }
}
