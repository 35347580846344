import AddIcon from "../icons/upload";
import { useState, useRef } from "react";

interface Props {
  id: string;
  placeholder?: string;
  onEnter?(value: string): void;
}

export default function Add({ id, placeholder, onEnter }: Props) {
  const [value, setValue] = useState("");
  const inputRef = useRef<HTMLInputElement>(null);

  return (
    <div className="flex">
      <input
        ref={inputRef}
        type="text"
        id={id}
        placeholder={placeholder}
        className="min-w-0 flex-1 rounded-l-md bg-slate-300 px-md py-sm outline-0 ring-1 ring-inset ring-slate-200 placeholder:text-slate-100 focus:ring-2 focus:ring-purple-200"
        value={value}
        onChange={(e) => setValue(e.target.value)}
        onKeyDown={(e) => {
          if (e.key === "Enter") {
            onEnter?.(value);
            setValue("");
          }
        }}
      />
      <button
        onClick={() => {
          onEnter?.(value);
          setValue("");
          inputRef.current?.focus();
        }}
        className="rounded-r-md border border-l-0 border-slate-200 bg-slate-300 px-md py-sm"
      >
        <AddIcon />
      </button>
    </div>
  );
}
