import { useEffect, useState, useRef } from "react";
import Dropdown from "../components/dropdown";
import DownIcon from "../icons/down";

type Options = string;

interface Props {
  value: string;
  options: Options[];
  textPrefix?: string;
  valueChanged: (value: string) => void;
  titleCaseOptions?: boolean;
}

export default function Select({
  value,
  options,
  textPrefix,
  valueChanged,
  titleCaseOptions
}: Props) {
  const [isOpen, setIsOpen] = useState(false);
  const [selected, setSelected] = useState(value);
  const dropdownRef = useRef<HTMLDivElement>(null);

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  const updatedSelected = (value: string) => {
    setIsOpen(false);
    setSelected(value);
    valueChanged(value);
  };

  const handleClickOutside = (event: MouseEvent) => {
    if (
      dropdownRef.current &&
      !dropdownRef.current.contains(event.target as Node)
    ) {
      setIsOpen(false);
    }
  };

  function toTitleCase(str: string) {
    let strArr = str.toLowerCase().split(' ');
    for (var i = 0; i < strArr.length; i++) {
      strArr[i] = strArr[i].charAt(0).toUpperCase() + strArr[i].slice(1);
    }
    return strArr.join(' ');
  }

  useEffect(() => {
    setSelected(value);
  }, [value]);

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <div className="relative" ref={dropdownRef}>
      <button
        onClick={toggleDropdown}
        className="flex w-full justify-between gap-sm rounded-md border border-slate-200 bg-slate-300 px-md py-sm"
      >
        <span>
          {textPrefix && `${textPrefix} `}
          {titleCaseOptions ? toTitleCase(selected) : selected}
        </span>
        <DownIcon />
      </button>
      {isOpen && <Dropdown options={options} optionClicked={updatedSelected} titleCaseOptions={titleCaseOptions} />}
    </div>
  );
}
