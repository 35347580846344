const Popular = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} fill="none">
    <path
      stroke="currentcolor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.5}
      d="m20 8-6 6-4-4-6 6"
    />
    <rect
      width={16}
      height={16}
      x={4}
      y={4}
      stroke="currentcolor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.5}
      rx={4}
    />
  </svg>
);
export default Popular;
