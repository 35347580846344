interface Props {
  onClick?: () => void;
  block?: boolean;
  primary?: boolean;
  size?: "sm";
  children: React.ReactNode;
  disabled?: boolean;
}

export default function Button({
  onClick,
  block,
  primary,
  size,
  children,
  disabled,
}: Props) {
  let blockClass = "";
  let colourClass = "";
  let sizeClass = "";

  if (block) {
    blockClass = "block w-full";
  }

  if (primary) {
    colourClass = "bg-purple-300 border-purple-300";
  } else {
    colourClass = "bg-slate-300 border-slate-200";
  }

  if (size === "sm") {
    sizeClass = "px-sm py-xs";
  } else {
    sizeClass = "px-md py-sm";
  }

  return (
    <button
      onClick={onClick}
      className={`rounded-md border font-semibold disabled:opacity-50 disabled:cursor-not-allowed ${sizeClass} ${blockClass} ${colourClass}`}
      disabled={disabled}
    >
      {children}
    </button>
  );
}
