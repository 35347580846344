import Button from "./button";
import Box from "./box";
import Input from "./input";
import Select from "./select";
import { Category } from "../types/Category";
import { useEffect, useState, useCallback } from "react";
import { Api } from "../services/Api";
import Alert from "./alert";
import { platformEvents } from "../services/eventbus/platformEvents";

interface Props {
  categories: Category[];
  onClose: (message?: string) => void;
}

export default function AddCategory({ categories, onClose }: Props) {
  const [name, setName] = useState("");
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);
  const [selectedParent, setSelectedParent] = useState<string | null>(null);
  const [selectedParentId, setSelectedParentId] = useState<number>(0);

  const [parents, setParents] = useState<{ name: string; id: number }[]>([]);

  // Flatten on frontend to prevent multiple API calls for categories on the backend
  const flattenCategories = useCallback(
    (
      categories: Category[],
      prefix: string = "",
      parentIds: number[] = [],
    ): { name: string; id: number }[] => {
      let result: { name: string; id: number }[] = [];
      categories.forEach((category) => {
        const categoryName = prefix
          ? `${prefix} / ${category.name}`
          : category.name;
        result.push({ name: categoryName, id: category.id });
        if (category.all_children.length > 0) {
          const children = flattenCategories(
            category.all_children,
            categoryName,
            [...parentIds, category.id],
          );
          result = result.concat(children);
        }
      });
      return result;
    },
    [],
  );

  const handleAddCategory = () => {
    if (
      name !== "" &&
      (selectedParentId !== undefined || selectedParentId !== null)
    ) {
      setLoading(true);
      Api("/admins/categories", {
        method: "POST",
        data: {
          parent_id: selectedParentId,
          name: name,
        },
      })
        .then((data) => {
          platformEvents.emit("generalSuccess", "Category added successfully!");
          setLoading(false);
          setSelectedParent(null);
          setName("");
          onClose();
        })
        .catch((data) => {
          if (data.error) {
            setError(data.error);
          } else if (data.message) {
            setError(data.message);
          }
          setLoading(false);
        });
    }
  };

  useEffect(() => {
    const flattenedCategories = flattenCategories(categories);
    setParents([{ name: "None", id: 0 }, ...flattenedCategories]);
  }, [flattenCategories, categories]);

  useEffect(() => {
    if (selectedParent !== null) {
      const parent = parents.find((p) => p.name === selectedParent);
      if (parent) setSelectedParentId(parent.id);
      else setSelectedParentId(0);
    }
  }, [parents, selectedParent]);

  return (
    <Box size="xl">
      <div className="space-y-lg">
        <h2 className="text-center text-xl">Add Category</h2>

        <div className="space-y-lg">
          {error !== "" && <Alert text={error} colour="danger" />}

          <div className="space-y-sm">
            <span className="block">Parent Category</span>
            <Select
              value="None"
              options={parents.map(({ name }) => name)}
              valueChanged={setSelectedParent}
            />
          </div>

          <div className="space-y-sm">
            <label htmlFor="name" className="block">
              Category Name
            </label>
            <Input
              type="text"
              id="name"
              name="name"
              placeholder="Category name"
              required
              value={name}
              onChange={(e) => setName(e.target.value)}
            />
          </div>
        </div>

        <div className="flex justify-between border-t border-slate-200 pt-lg">
          <Button onClick={onClose} disabled={loading}>
            Cancel
          </Button>
          <Button
            onClick={handleAddCategory}
            disabled={name === "" || loading}
            primary
          >
            Add
          </Button>
        </div>
      </div>
    </Box>
  );
}
