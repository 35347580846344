type Options = string;

interface Props {
  options: Options[];
  optionClicked: (value: string) => void;
  titleCaseOptions?: boolean
}

export default function Dropdown({ options, optionClicked, titleCaseOptions }: Props) {

  function toTitleCase(str: string) {
    let strArr = str.toLowerCase().split(' ');
    for (var i = 0; i < strArr.length; i++) {
      strArr[i] = strArr[i].charAt(0).toUpperCase() + strArr[i].slice(1);
    }
    return strArr.join(' ');
  }

  return (
    <div className="absolute right-0 top-full z-10 mt-sm max-h-3xl overflow-y-auto rounded-md border border-slate-200 bg-slate-300 py-sm">
      {options.map((option, index) => (
        <button
          key={index}
          className="block w-full px-md py-sm text-left hover:bg-slate-200"
          onMouseDown={() => optionClicked(option)}
        >
          {titleCaseOptions ? toTitleCase(option) : option}
        </button>
      ))}
    </div>
  );
}
