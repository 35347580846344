const Featured = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} fill="none">
    <path
      stroke="currentcolor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.5}
      d="M6 20V6a2 2 0 0 1 2-2h8a2 2 0 0 1 2 2v14l-6-4-6 4Z"
    />
  </svg>
);
export default Featured;
