const Cart = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} fill="none">
    <path
      stroke="currentcolor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.5}
      d="M5 9h14v7a4 4 0 0 1-4 4H9a4 4 0 0 1-4-4V9Z"
    />
    <path
      stroke="currentcolor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.5}
      d="M9 12V7a3 3 0 0 1 3-3v0a3 3 0 0 1 3 3v5"
    />
  </svg>
);
export default Cart;
