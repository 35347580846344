import { dir } from 'console';
import { useRef, useEffect, useState } from 'react';

interface Props {
  threshold: number;
  direction: 'up' | 'down' | 'left' | 'right';
  touchStart: (event: TouchEvent) => void;
  touchMove: (delta: { x: number; y: number }) => void;
  touchEnd: () => void;
  thresholdMet: () => void;
}

const useSwipe = ({ threshold, direction, touchStart, touchMove, touchEnd, thresholdMet }: Props): void => {
  const startXRef = useRef<number | null>(null);
  const startYRef = useRef<number | null>(null);
  const [triggered, setTriggered] = useState<Boolean>(false);

  const handleTouchStart = (event: TouchEvent) => {
    touchStart(event);
    startXRef.current = event.touches[0].clientX;
    startYRef.current = event.touches[0].clientY;
  };

  const handleTouchMove = (event: TouchEvent) => {
    if (startXRef.current !== null && startYRef.current !== null) {
      const deltaX = event.touches[0].clientX - startXRef.current;
      const deltaY = event.touches[0].clientY - startYRef.current;


        if (deltaX > 0) {
            if (direction === 'right') {
                touchMove({ x: deltaX, y: 0 });
            }
            if (Math.abs(deltaX) > threshold && direction === 'right') {
                startXRef.current = null;
                startYRef.current = null;
                setTriggered(true);
                thresholdMet();
            }
        }

        if (deltaX < 0) {
            if (direction === 'left') {
                touchMove({ x: deltaX, y: 0 });
            }
            if (Math.abs(deltaX) > threshold && direction === 'left') {
                startXRef.current = null;
                startYRef.current = null;
                setTriggered(true);
                thresholdMet();
            }
        }

        if (deltaY < 0) {
            if (direction === 'up') {
                touchMove({ x: 0, y: deltaY });
            }
            if (Math.abs(deltaY) > threshold && direction === 'up') {
                startXRef.current = null;
                startYRef.current = null;
                setTriggered(true);
                thresholdMet();
            }
        }

        if (deltaY > 0) {
            if (direction === 'down') {
                touchMove({ x: 0, y: deltaY });
            }
            if (Math.abs(deltaY) > threshold && direction === 'down') {
                startXRef.current = null;
                startYRef.current = null;
                setTriggered(true);
                thresholdMet();
            }
        }
    }
  };

  const handleTouchEnd = () => {
    if (!triggered) {
      startXRef.current = null;
      startYRef.current = null;
      touchEnd();
    }
  };

  useEffect(() => {
    document.addEventListener('touchstart', handleTouchStart);
    document.addEventListener('touchmove', handleTouchMove);
    document.addEventListener('touchend', handleTouchEnd);

    return () => {
      document.removeEventListener('touchstart', handleTouchStart);
      document.removeEventListener('touchmove', handleTouchMove);
      document.removeEventListener('touchend', handleTouchEnd);
    };
  }, []);

};

export default useSwipe;