import Checkbox from "./checkbox";
import Chip from "./chip";
import * as changeCase from "change-case";
import LikeIcon from "../icons/like";

export interface LikedContentProps {
  content_id: number;
  is_private: Boolean;
  image: string;
  title: string;
  created_at: Date;
  price_inc_tax_as_currency: string;
  likes: number;
  addToBasket?: (content_id: number) => void;
}
export default function LikeCard({
  content_id,
  is_private,
  image,
  title,
  created_at,
  price_inc_tax_as_currency,
  likes,
}: LikedContentProps) {
  return (
    <article className="flex min-w-0 flex-col gap-md rounded-md bg-slate-400 p-md lg:flex-row-reverse">
      <div className="flex min-w-0 flex-1 flex-col gap-md lg:flex-row">
        <img
          src={image}
          alt={title}
          width={96}
          height={64}
          className="hidden rounded-md lg:block"
        />

        <img
          src={image}
          alt={title}
          width={960}
          height={640}
          className="rounded-md lg:hidden"
        />

        <div className="min-w-0 space-y-sm">
          <div className="flex items-center gap-sm">
            {is_private && <Chip text="Private" colour="primary" />}
            <h2 className="truncate">{title}</h2>
          </div>

          <div className="flex gap-md text-slate-100">
            <span>{created_at.toLocaleDateString()}</span>
            <span>{price_inc_tax_as_currency}</span>
            <div className="flex gap-sm">
              <LikeIcon />
              <span>{likes}</span>
            </div>
          </div>
        </div>
      </div>
    </article>
  );
}
