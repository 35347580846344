import { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import {} from "react-router-dom";
import Button from "../../components/button";
import Chip from "../../components/chip";
import RoundButton from "../../components/roundbutton";
import LikeIcon from "../../icons/like";
import { Content } from "../../types/Content";
import { Api } from "../../services/Api";
import { useCart } from "../../services/CartContext";
import * as changeCase from "change-case";
import { platformEvents } from "../../services/eventbus/platformEvents";

const View = () => {
  const [loading, setLoading] = useState(true);
  const [content, setContent] = useState<Content | null>(null);
  const [mainImage, setMainImage] = useState<string>("/images/placeholder.png");
  const { id } = useParams();
  const { addToCart } = useCart();

  useEffect(() => {
    Api("/browse/" + id).then((data) => {
      setContent(data);
      if (data.thumbnails.length > 0) {
        setMainImage(data?.thumbnails[0]);
      }
      setLoading(false);
    });
  }, [id]);

  const handleAddToCart = () => {
    if (content !== null) {
      addToCart(content);
    }
  };
  const handleLike = () => {
    Api("/like/content/" + id, { method: "POST" }).then((data) => {
      setContent(data);
    });
  };

  const handleSubscribe = () => {
    Api("/subscribe/creator/" + content?.creator.id, { method: "POST" }).then(
      (_) => {
        Api("/browse/" + id).then((data) => {
          setContent(data);
          if (data.creator.is_subscribed) {
            platformEvents.emit("subscriptionAdded");
          } else {
            platformEvents.emit("subscriptionRemoved");
          }
        });
      },
    );
  };

  if (loading) return <p>Loading...</p>;

  return (
    <article className="flex-1 space-y-lg px-md py-lg">
      <div className="mx-auto max-w-3xl space-y-lg">
        <div className="space-y-md">
          <h1 className="text-lg md:text-xl">{content?.description?.title}</h1>
          <p className="text-slate-100">
            {content?.description?.short_description}
          </p>
        </div>

        <div className="flex flex-col gap-md sm:flex-row">
          <div className="flex flex-1 items-center justify-between gap-md">
            <Link to={content?.creator?.url ?? "#"} className="flex gap-sm">
              <img
                src={content?.creator?.avatar}
                alt={content?.description?.title}
                width={24}
                height={24}
                className="rounded-full"
              />
              <span className="truncate">{content?.creator?.name}</span>
            </Link>
            <div className="flex gap-md">
              <RoundButton active={content?.liked} onClick={handleLike}>
                <LikeIcon />
              </RoundButton>
            </div>
          </div>
          {!content?.owned ? (
          <div>
            <Button onClick={handleAddToCart} primary block>
              Add to cart
            </Button>
          </div>
          ) : (<p className="my-auto">Owned</p>)}
        </div>
      </div>

      <div className="mx-auto max-w-4xl space-y-md">
        <img
          src={mainImage}
          alt={changeCase.kebabCase(
            content?.description?.title + " main thumbnail",
          )}
          width={960}
          height={640}
          className="rounded-md"
        />
        <div className="flex flex-wrap justify-center gap-md">
          {content?.thumbnails?.map((thumbnail, index) => (
            <img
              onClick={() => {
                setMainImage(thumbnail);
              }}
              src={thumbnail}
              alt={changeCase.kebabCase(
                content?.description?.title +
                  " thumbnail " +
                  (index + 1).toString(),
              )}
              width={96}
              height={64}
              className="rounded-md"
            />
          ))}
        </div>
      </div>

      <div className="mx-auto max-w-3xl space-y-lg">
        <h2 className="text-lg">Overview</h2>
        <div className="flex flex-wrap gap-sm">
          {content?.tags.map((tag) => (
            <Chip text={tag.tag} colour="secondary" />
          ))}
        </div>
        <div className="space-y-md">
          <p className="text-slate-100">
            {content?.description.long_description}
          </p>
        </div>

        <div className="flex items-center justify-between gap-md">
          <Link to={content?.creator.url || ""} className="flex gap-sm">
            <img
              src={content?.creator.avatar}
              alt={content?.creator.name}
              width={24}
              height={24}
              className="rounded-full"
            />
            <span className="truncate">{content?.creator.name}</span>
          </Link>
          <Button onClick={handleSubscribe}>
            {content?.creator.is_subscribed ? "Unsubscribe" : "Subscribe"}
          </Button>
        </div>
      </div>
    </article>
  );
};

export default View;
