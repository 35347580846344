import React, { useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import Alert from "../../components/alert";
import Button from "../../components/button";
import Input from "../../components/input";
import { Api } from "../../services/Api";

export default function Register() {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const location = useLocation();
  const navigate = useNavigate();

  const handleRegister = async (event: React.FormEvent) => {
    event.preventDefault();
    const formData = new FormData(event.target as HTMLFormElement);
    setLoading(true);
    Api("/auth/register", {
      method: "POST",
      data: {
        name: formData.get("name") as string,
        username: formData.get("username") as string,
        email: formData.get("email") as string,
        password: formData.get("password") as string,
      },
    })
      .then(() => {
        const params = new URLSearchParams(location.search);
        navigate(params.get("p") || "/browse");
      })
      .catch((data) => {
        if (data.error) {
          setError(data.error);
        } else if (data.message) {
          setError(data.message);
        }
        setLoading(false);
      });
  };

  return (
    <div className="space-y-lg">
      <div className="space-y-sm text-center">
        <h1 className="text-xl">Create your account</h1>
        <p className="text-slate-100">Please enter your details.</p>
      </div>

      <div className="rounded-md bg-slate-300 px-md py-sm">
        <p>
          This registration page is exclusively for individuals who wish to
          contribute and/or sell their unique content. For more information
          visit{" "}
          <Link
            className="text-purple-100 underline"
            to="https://www.immersivereality.co.uk/"
            target="_blank"
          >
            our website
          </Link>
          .
        </p>
      </div>

      <form onSubmit={handleRegister} method="POST" className="space-y-lg">
        {error ? <Alert text={error} colour="danger" /> : null}

        <div className="space-y-sm">
          <label htmlFor="name" className="block">
            Name
          </label>
          <Input
            type="text"
            id="name"
            name="name"
            disabled={loading}
            placeholder="Your full name"
            required
          />
        </div>

        <div className="space-y-sm">
          <label htmlFor="username" className="block">
            Username
          </label>
          <Input
            type="text"
            id="username"
            name="username"
            disabled={loading}
            placeholder="Username"
            required
          />
        </div>

        <div className="space-y-sm">
          <label htmlFor="email" className="block">
            Email
          </label>
          <Input
            type="email"
            id="email"
            name="email"
            disabled={loading}
            placeholder="example@domain.com"
            required
          />
        </div>

        <div className="space-y-sm">
          <label htmlFor="password" className="block">
            Password
          </label>
          <Input
            type="password"
            id="password"
            name="password"
            disabled={loading}
            placeholder="Password"
            required
          />
        </div>

        <Button block primary disabled={loading}>
          {loading ? "Loading..." : "Sign up"}
        </Button>

        <div className="border-t border-slate-200 pt-lg text-center">
          <span className="text-slate-100">Already have an account? </span>
          <Link to="/login" className="text-purple-100 underline">
            Sign in
          </Link>
        </div>
      </form>
    </div>
  );
}
