import { createContext, useContext, useState, ReactNode } from "react";
import { CartLine } from "../types/CartLine";
import { Content } from "../types/Content";
import { Api } from "./Api";
import { useSanctum } from "react-sanctum";
import { platformEvents } from "./eventbus/platformEvents";

interface CartContextType {
  cart: CartLine[];
  sub_total: number;
  sub_total_as_currency: string;
  tax: [];
  tax_total_as_currency: string;
  total: number;
  total_as_currency: string;
  addToCart: (content: Content) => void;
  removeFromCart: (lineId: number) => void;
  updateQuantity: (lineId: number, newQuantity: number) => void;
}

const initialCart: CartLine[] = [];

const CartContext = createContext<CartContextType | undefined>(undefined);

export const useCart = (): CartContextType => {
  const context = useContext(CartContext);
  if (!context) {
    throw new Error("useCart must be used within a CartProvider");
  }
  return context;
};

interface CartProviderProps {
  children: ReactNode;
}

export const CartProvider = ({ children }: CartProviderProps) => {
  const { authenticated, checkAuthentication } = useSanctum();
  const [initialised, setInitialised] = useState(false);
  const [cart, setCart] = useState<CartLine[]>(initialCart);
  const [sub_total, setSubTotal] = useState(0);
  const [sub_total_as_currency, setSubTotalAsCurrency] = useState("");
  const [tax, setTax] = useState<[]>([]);
  const [tax_total_as_currency, setTaxTotalAsCurrency] = useState("");
  const [total, setTotal] = useState(0);
  const [total_as_currency, setTotalAsCurrency] = useState("");

  const addToCart = async (content: Content) => {
    if (authenticated || (await checkAuthentication())) {
      Api("/cart/add", {
        method: "POST",
        data: { content_id: content.id, quantity: 1 },
      }).then((data) => {
        refreshCart(data);
        platformEvents.emit("itemAddedToCart");
      });
    }
  };

  const removeFromCart = async (lineId: number) => {
    if (authenticated || (await checkAuthentication())) {
      Api("/cart/" + lineId, { method: "DELETE" }).then((data) => {
        refreshCart(data);
        platformEvents.emit("itemRemovedFromCart");
      });
    }
  };

  const updateQuantity = async (lineId: number, newQuantity: number) => {
    // Not implemented for this project
    return;
    /*if (await checkAuthentication()) {
    Api("/cart/" + lineId, {
      method: "PATCH",
      data: {
        quantity: newQuantity,
      },
    }).then((data) => {
      refreshCart(data);
      callback();
    });
    }*/
  };

  const refreshCart = async (data: any) => {
    if (data) {
      setSubTotal(data.sub_total);
      setSubTotalAsCurrency(data.sub_total_as_currency);
      setTax(data.tax_array);
      setTaxTotalAsCurrency(data.tax_total_as_currency);
      setTotal(data.total);
      setTotalAsCurrency(data.total_as_currency);
    }
    if (data.cart_lines) {
      let cartLines = [];
      for (let cart_line of data.cart_lines) {
        cartLines.push({
          id: cart_line.id,
          content_id: cart_line.content_id,
          content: cart_line.content,
          quantity: cart_line.quantity,
          price: cart_line.price,
          price_as_currency: cart_line.price_as_currency,
          tax: cart_line.tax,
          tax_as_currency: cart_line.tax_as_currency,
          total: cart_line.total,
          total_as_currency: cart_line.total_as_currency,
        });
      }
      setCart(cartLines);
    }
  };

  const cartActions: CartContextType = {
    cart,
    sub_total,
    sub_total_as_currency,
    tax,
    tax_total_as_currency,
    total,
    total_as_currency,
    addToCart,
    removeFromCart,
    updateQuantity,
  };

  if (!initialised) {
    if (authenticated) {
      Api("/cart").then((data) => {
        refreshCart(data);
        setInitialised(true);
      });
    }
  }

  return (
    <CartContext.Provider value={cartActions}>{children}</CartContext.Provider>
  );
};
