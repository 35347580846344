import Chip from "../components/chip";
import Select from "../components/select";
import { Category } from "../types/Category";

interface Props {
  initialOption: string;
  options: string[];
  textPrefix: string;
  categories: Category[];
  valueChanged: (value: string) => void;
}

export default function Sort({
  initialOption,
  options,
  textPrefix,
  categories,
  valueChanged,
}: Props) {
  return (
    <div className="flex flex-col gap-md md:flex-row md:items-end md:justify-between">
      <div className="flex flex-wrap gap-sm">
        {categories.map((cat, index) => (
          <Chip key={index} text={cat.name} colour="secondary" />
        ))}
      </div>
      <div className="flex flex-none justify-end">
        <Select
          valueChanged={valueChanged}
          value={initialOption}
          options={options}
          textPrefix={textPrefix}
        />
      </div>
    </div>
  );
}
