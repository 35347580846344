import { Link } from "react-router-dom";

export default function Footer() {
  return (
    <footer className="flex flex-wrap justify-center gap-md px-lg py-md text-slate-100">
      <Link to="#">Terms and Conditions</Link>
      <Link to="#">Privacy Policy</Link>
    </footer>
  );
}
