import Button from "./button";
import Box from "./box";
import Input from "./input";
import Select from "./select";
import { useEffect, useState } from "react";
import { Api } from "../services/Api";
import { platformEvents } from "../services/eventbus/platformEvents";
import Chip from "./chip";
import Add from "./add";

interface Props {
  userId?: number;
  userType?: string;
  onClose: (message?: string) => void;
}

export default function UserModal({ userId, userType, onClose }: Props) {
  const [loading, setLoading] = useState(false);

  const [name, setName] = useState<string>("");
  const [email, setEmail] = useState<string>("");
  const [username, setUsername] = useState<string>("");
  const [password, setPassword] = useState<string>("");
  const [password_confirmation, setPasswordConfirmation] = useState<string>("");
  const [addressLine1, setAddressLine1] = useState<string>("");
  const [addressLine2, setAddressLine2] = useState<string>("");
  const [addressLine3, setAddressLine3] = useState<string>("");
  const [city, setCity] = useState<string>("");
  const [postcode, setPostcode] = useState<string>("");
  const [stateId, setStateId] = useState<number | null>(null);
  const [countryId, setCountryId] = useState<number | null>(null);
  const [devices, setDevices] = useState<string[]>([]);
  const [type, setType] = useState<string>(userType?.toLowerCase() || 'user');

  const [states, setStates] = useState<{ id: number; name: string }[]>([]);
  const [countries, setCountries] = useState<{ id: number; name: string }[]>(
    [],
  );

  const handleAddUser = () => {
    setLoading(true);
    Api("/admins/users", {
      method: "POST",
      data: {
        type: type,
        username: username,
        email: email,
        name: name,
        password: password,
        password_confirmation: password_confirmation,
        addressLine1: addressLine1,
        addressLine2: addressLine2,
        addressLine3: addressLine3,
        city: city,
        postcode: postcode,
        stateId: stateId,
        countryId: countryId,
        devices: devices,
      },
    })
      .then((data) => {
        platformEvents.emit("generalSuccess", "User added successfully!");
        setLoading(false);
        onClose();
      })
      .catch((data) => {
        if (data.message) {
          platformEvents.emit("generalFailure", data.message);
        } else if (data.error) {
          platformEvents.emit("generalFailure", data.error);
        }
        setLoading(false);
      });
  };

  const handleEditUser = () => {
    setLoading(true);
    Api("/admins/users/" + type + "/" + userId, {
      method: "PATCH",
      data: {
        username: username,
        email: email,
        name: name,
        password: password,
        password_confirmation: password_confirmation,
        addressLine1: addressLine1,
        addressLine2: addressLine2,
        addressLine3: addressLine3,
        city: city,
        postcode: postcode,
        stateId: stateId,
        countryId: countryId,
        devices: devices,
      },
    })
      .then((data) => {
        platformEvents.emit("generalSuccess", "User updated successfully!");
        setLoading(false);
        onClose();
      })
      .catch((data) => {
        if (data.message) {
          platformEvents.emit("generalFailure", data.message);
        } else if (data.error) {
          platformEvents.emit("generalFailure", data.error);
        }
        setLoading(false);
      });
  };

  const handleAddDevice = (device: string) => {
    if (!devices.includes(device)) setDevices([...devices, device]);
  };

  const handleRemoveDevice = (device: string) => {
    setDevices(devices.filter((d) => d !== device));
  };

  useEffect(() => {
    // Reload states and set state ID to null
    if (countryId) {
      Api("/countries/" + countryId + "/states")
        .then((data) => {
          setStates(data);
        })
        .catch((data) => {
          if (data.message) {
            platformEvents.emit("generalFailure", data.message);
          } else if (data.error) {
            platformEvents.emit("generalFailure", data.error);
          }
        });
    }
  }, [countryId]);

  useEffect(() => {

    Api("/countries")
      .then((data) => {
        setCountries(data);
      })
      .catch((data) => {
        if (data.message) {
          platformEvents.emit("generalFailure", data.message);
        } else if (data.error) {
          platformEvents.emit("generalFailure", data.error);
        }
      })
      .finally(() => {
        if (userId && type) {
          Api("/admins/users/" + type.toLowerCase() + "/" + userId)
            .then((data) => {
              setName(data.name);
              setEmail(data.email);
              setUsername(data.username);
              setAddressLine1(data.addressLine1);
              setAddressLine2(data.addressLine2);
              setAddressLine3(data.addressLine3);
              setCity(data.city);
              setPostcode(data.postcode);
              if (data.countryId) {
                setCountryId(data.countryId);
                if (data.stateId) {
                  setStateId(data.stateId);
                }
              }
              setDevices(data.devices);
          }).finally(() => {
            setLoading(false);
          })
        } else {
          setCountryId(222);
          setLoading(false);
        }
      });
  }, []);

  return (
    <Box size="xl">
      <div className="space-y-lg">
        <h2 className="text-center text-xl">{!userId ? 'Add' : 'Edit'} User</h2>

        { !userId && (
          <div className="space-y-sm">
            <label htmlFor="type" className="block">
              User Type
            </label>
            <Select
              options={["user", "admin"]}
              value={type}
              valueChanged={(value) => setType(value)}
              titleCaseOptions={true}
            />
          </div>
        )}

        <div className="space-y-lg">
          <div className="space-y-sm">
            <label htmlFor="name" className="block">
              Name
            </label>
            <Input
              type="text"
              id="name"
              placeholder="The users full name"
              value={name}
              onChange={(e) => setName(e.target.value)}
            />
          </div>

          <div className="space-y-sm">
            <label htmlFor="username" className="block">
              Username
            </label>
            <Input
              type="text"
              id="username"
              placeholder="The username of the user"
              value={username}
              onChange={(e) => setUsername(e.target.value)}
            />
          </div>

          <div className="space-y-sm">
            <label htmlFor="email" className="block">
              Email
            </label>
            <Input
              type="email"
              id="email"
              name="email"
              placeholder="example@domain.com"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
          </div>

          <div className="space-y-sm">
            <label htmlFor="password" className="block">
              Password
            </label>
            <Input
              type="password"
              id="password"
              name="password"
              placeholder="Password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
          </div>

          <div className="space-y-sm">
            <label htmlFor="confirm-password" className="block">
              Confirm Password
            </label>
            <Input
              type="password"
              id="confirm-password"
              name="password_confirmation"
              placeholder="Confirm Password"
              value={password_confirmation}
              onChange={(e) => setPasswordConfirmation(e.target.value)}
            />
          </div>

          <div className="space-y-sm">
            <label htmlFor="address-line-1" className="block">
              Address Line 1
            </label>
            <Input
              type="text"
              id="address-line-1"
              placeholder="Address Line 1"
              value={addressLine1}
              onChange={(e) => setAddressLine1(e.target.value)}
            />
          </div>

          <div className="space-y-sm">
            <label htmlFor="address-line-2" className="block">
              Address Line 2
            </label>
            <Input
              type="text"
              id="address-line-2"
              placeholder="Address Line 2"
              value={addressLine2}
              onChange={(e) => setAddressLine2(e.target.value)}
            />
          </div>

          <div className="space-y-sm">
            <label htmlFor="address-line-3" className="block">
              Address Line 3
            </label>
            <Input
              type="text"
              id="address-line-3"
              placeholder="Address Line 3"
              value={addressLine3}
              onChange={(e) => setAddressLine3(e.target.value)}
            />
          </div>

          <div className="space-y-sm">
            <label htmlFor="city" className="block">
              City
            </label>
            <Input
              type="text"
              id="city"
              placeholder="City"
              value={city}
              onChange={(e) => setCity(e.target.value)}
            />
          </div>

          <div className="space-y-sm">
            <label htmlFor="postcode" className="block">
              Postal Code
            </label>
            <Input
              type="text"
              id="postcode"
              placeholder="Postal Code"
              value={postcode}
              onChange={(e) => setPostcode(e.target.value)}
            />
          </div>

          <div className="space-y-sm">
            <label htmlFor="country" className="block">
              Country
            </label>
            <Select
              options={countries.map((country) => country.name.toString())}
              value={
                countryId
                  ? countries.find((c) => c.id === countryId)?.name || ""
                  : ""
              }
              valueChanged={(value) => {
                const country = countries.find((c) => c.name === value);
                setStateId(null);
                setCountryId(country?.id || null);
              }}
            />
          </div>

          <div className="space-y-sm">
            <label htmlFor="state" className="block">
              State/County
            </label>
            <Select
              options={states.map((country) => country.name.toString())}
              value={
                stateId ? states.find((c) => c.id === stateId)?.name || "" : ""
              }
              valueChanged={(value) => {
                const state = states.find((c) => c.name === value);
                setStateId(state?.id || null);
              }}
            />
          </div>

          {type == 'user' && (
            <div className="space-y-md">
              <div className="space-y-sm">
                <div className="flex justify-between">
                  <div className="flex items-center gap-sm">
                    <span className="h-xs w-xs rounded-full bg-purple-100"></span>
                    <label htmlFor="devices">Devices (required)</label>
                  </div>
                  <span className="hidden text-slate-100 sm:inline">
                    Enter a device ID and hit enter to add.
                  </span>
                </div>
                <Add
                  id="devices"
                  placeholder="Device ID"
                  onEnter={handleAddDevice}
                />
              </div>
              <div className="flex flex-wrap gap-sm">
                {devices.map((id, index) => (
                  <Chip
                    key={index}
                    colour="secondary"
                    text={id}
                    onClick={(text) => handleRemoveDevice(text)}
                  />
                ))}
              </div>
            </div>
          )}

          <div className="flex justify-between border-t border-slate-200 pt-lg">
            <Button onClick={onClose} disabled={loading}>
              Cancel
            </Button>
            <Button onClick={!userId ? handleAddUser : handleEditUser} disabled={loading} primary>
              { !userId ? 'Add' : 'Update'}
            </Button>
          </div>
        </div>
      </div>
    </Box>
  );
}
