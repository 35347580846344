const Latest = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} fill="none">
    <circle
      cx={12}
      cy={12}
      r={8}
      stroke="currentcolor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.5}
    />
    <path
      stroke="currentcolor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.5}
      d="m12 8 1.176 2.382 2.628.382-1.902 1.854.45 2.618L12 14l-2.351 1.236.449-2.618-1.902-1.854 2.628-.382L12 8Z"
    />
  </svg>
);
export default Latest;
