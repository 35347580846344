import Table from "../../components/table";
import TableHead from "../../components/tablehead";
import TableRow from "../../components/tablerow";
import TableCell from "../../components/tablecell";
import { useEffect, useState } from "react";
import Popup from "../../components/popup";
import { Api } from "../../services/Api";
import { platformEvents } from "../../services/eventbus/platformEvents";
import Button from "../../components/button";
import UserModal from "../../components/usermodal";

interface Row {
  username: string;
  type: string;
  created: string;
  name: string;
  id: number;
}

export default function Users() {
  const [addUserIsOpen, setUserIsOpen] = useState(false);
  const headers = ["Name", "Username", "Type", "Created", "Actions"];
  const [rows, setRows] = useState<Row[]>([]);
  const [userType, setUserType] = useState<string | undefined>(undefined);
  const [userId, setUserId] = useState<number | undefined>(undefined);

  const toggleUserModal = () => {
    if (addUserIsOpen) {
      setUserType(undefined);
      setUserId(undefined);
    }
    setUserIsOpen(!addUserIsOpen);
  };

  const refreshUsers = () => {
    Api("/admins/users")
      .then((data) => {
        if (data) {
          setRows(data);
        }
      })
      .catch((data) => {
        if (data.message) {
          platformEvents.emit("generalFailure", data.message);
        } else if (data.error) {
          platformEvents.emit("generalFailure", data.error);
        }
      });
  };

  const handleEditUser = (type: string, id: number) => {
  }

  useEffect(() => {
    refreshUsers();
  }, []);

  useEffect(() => {
    const unsubscribeSuccess = platformEvents.on("generalSuccess", (_) => {
      refreshUsers();
    });

    return () => {
      unsubscribeSuccess();
    };
  }, []);

  return (
    <>
      <div className="flex-1 space-y-md p-md">
        <div className="flex gap-sm">
          <Button onClick={toggleUserModal}>Add</Button>
        </div>
        <Table>
          <TableRow>
            {headers.map((header, index) => (
              <TableHead key={index}>
                <span
                  className={index !== 0 ? "block truncate text-right" : ""}
                >
                  {header}
                </span>
              </TableHead>
            ))}
          </TableRow>
          {rows.map((row, index) => (
            <TableRow key={index}>
              <TableCell>
                <span className={"block truncate text-left"}>{row.name}</span>
              </TableCell>
              <TableCell>
                <span className={"block truncate text-right"}>
                  {row.username}
                </span>
              </TableCell>
              <TableCell>
                <span className={"block truncate text-right"}>{row.type}</span>
              </TableCell>
              <TableCell>
                <span className={"block truncate text-right"}>
                  {row.created}
                </span>
              </TableCell>
              <TableCell>
                <div className="text-right">
                  <Button onClick={() => {
                    setUserId(row.id);
                    setUserType(row.type);
                    toggleUserModal();
                  }} size="sm">Edit</Button>
                </div>
              </TableCell>
            </TableRow>
          ))}
        </Table>
      </div>
      {addUserIsOpen && (
        <Popup>
          <UserModal userId={userId} userType={userType} onClose={toggleUserModal} />
        </Popup>
      )}
    </>
  );
}
