interface Props {
  text: string;
  colour: "success" | "danger";
}

export default function Alert({ text, colour }: Props) {
  let colourClass = "";

  switch (colour) {
    case "success":
      colourClass = "text-emerald-100 border-emerald-200 bg-emerald-500";
      break;
    case "danger":
      colourClass = "text-amber-100 border-amber-200 bg-amber-500";
      break;
  }

  return (
    <div className={`rounded-md border px-md py-sm ${colourClass}`}>{text}</div>
  );
}
