import Marzipano from 'marzipano'
import { VideoAsset } from '../../types/VideoAsset'

import { SceneSpec } from '../useMarzipano'
import { Scene, Viewer } from '../../types/Marzipano'


const defaultResolution = 5376
const defaultFov = Math.PI * 1 / 3
const defaultViewParams = { yaw: 0, pitch: 0, roll: 0, defaultFov }
const defaultViewLimiter = Marzipano.RectilinearView.limit.traditional(defaultResolution, defaultFov)
const defaultLevels = [
  { width: defaultResolution }
]


function loadScene(viewer: Viewer, sceneSpec: SceneSpec) {
  const { imageUrl, movieUrl, type } = sceneSpec

  const levels = sceneSpec.levels ?? defaultLevels

  const viewParams = sceneSpec.viewParams ?? defaultViewParams
  const viewLimiter = sceneSpec.viewLimiter ?? defaultViewLimiter
  const view = new Marzipano.RectilinearView(viewParams, viewLimiter)

  const geometry = type === 'equirect'
    ? new Marzipano.EquirectGeometry(levels)
    : new Marzipano.CubeGeometry(levels)
  let source = null;
  
  if (imageUrl !== '') {
    source = typeof imageUrl === 'function'
      ? new Marzipano.ImageUrlSource(imageUrl)
      : Marzipano.ImageUrlSource.fromString(imageUrl)
  } else if (movieUrl !== '') {
    const asset = new VideoAsset();
    source = new Marzipano.SingleAssetSource(asset);
  }

  if (source !== null)
    return viewer.createScene({ source, geometry, view })
}

function unloadScene(viewer: Viewer, scene: Scene) {
  viewer.destroyScene(scene)
}

type OnLoadFunc = () => void

let currentListener: OnLoadFunc | null = null

function switchScene(viewer: Viewer, scene: Scene, transitionDuration?: number, onLoad?: OnLoadFunc) {
  if (viewer && scene) {
    if (onLoad) {
      if (currentListener) {
        viewer.stage().removeEventListener('renderComplete', currentListener)
      }
      currentListener = onLoad
      viewer.stage().addEventListener('renderComplete', onLoad)
    }

    scene.switchTo({ transitionDuration })
  }
}

function loadVideoInSync(url: string, syncElement: HTMLVideoElement | null | undefined, cb: (err?: Error, element?: HTMLVideoElement) => void) {
  cb = once(cb);
  var element = document.createElement('video');
  element.crossOrigin = 'anonymous';
  element.preload = 'auto';
  element.autoplay = true;
  element.loop = true;
  element.playsInline = true;
  element.webkitPlaysInline = true;
  element.src = url;

  waitForReadyState(element, element.HAVE_ENOUGH_DATA, 0.2, function() {
    emitter.emit("loaded");
  });
}

function waitForReadyState(element: HTMLVideoElement, readyState: number, interval: number | undefined, callback: { (): void; (): void; (arg0: null, arg1: boolean): void }) {
  var timer = setInterval(function() {
    if(element.readyState >= readyState) {
      clearInterval(timer);
      callback(null, true);
    }
  }, interval);
}

function once(f: { (err?: Error | undefined, element?: HTMLVideoElement | undefined): void; apply?: any }) {
  var called = false;
  return function() {
    if(!called) {
      called = true;
      f.apply(null, arguments);
    }
  };
}

export type { OnLoadFunc }
export { loadScene, unloadScene, switchScene }