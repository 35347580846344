import { ReactNode } from "react";
import Chip from "../components/chip";
import MoreIcon from "../icons/morehorizontal";

interface Props {
  icon: ReactNode;
  heading: string;
  number: string;
  percent: string;
  chipColour: "success" | "danger";
}

export default function Stat({
  icon,
  heading,
  number,
  percent,
  chipColour,
}: Props) {
  return (
    <div className="space-y-sm rounded-md bg-slate-400 px-lg py-md">
      <div className="flex gap-sm">
        {icon}
        <h2 className="flex-1 truncate">{heading}</h2>
      </div>
      <div className="flex items-center justify-between gap-sm">
        <h3 className="truncate text-2xl text-slate-100 2xl:text-3xl">
          {number}
        </h3>
        {percent != "" && (
        <div className="flex-none">
          <Chip text={percent} colour={chipColour} />
        </div>
        )}
      </div>
    </div>
  );
}
