import { Outlet } from "react-router-dom";
import Box from "../components/box";
import { useEffect, useState } from "react";
import { useSanctum } from "react-sanctum";
import { useLocation, useNavigate } from "react-router-dom";

const AuthLayout = () => {
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();
  const location = useLocation();
  const { authenticated } = useSanctum();

  useEffect(() => {
    if (authenticated) {
      const params = new URLSearchParams(location.search);
      navigate(params.get("p") || "/browse");
      return;
    }
    setLoading(false);
  }, [navigate, location, authenticated]);

  return (
    <>
      {loading === false && (
        <Box size="lg">
          <Outlet />
        </Box>
      )}
    </>
  );
};

export default AuthLayout;
