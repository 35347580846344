interface Props {
  size: "lg" | "xl";
  children: React.ReactNode;
}

export default function Box({ size, children }: Props) {
  let sizeClass = "";

  switch (size) {
    case "lg":
      sizeClass = "max-w-lg";
      break;
    case "xl":
      sizeClass = "max-w-xl";
      break;
  }
  return (
    <div
      className={`m-auto w-10/12 rounded-md bg-slate-400 p-lg sm:p-xl ${sizeClass}`}
    >
      {children}
    </div>
  );
}
