import { useEffect } from "react";
import SearchIcon from "../icons/search";

interface Props {
  query?: string;
  onClose?: () => void;
  callback?: (query: string) => void;
}

export default function Search({ query, onClose, callback }: Props) {
  useEffect(() => {
    callback && query && callback(query);
  });

  return (
    <form
      onSubmit={(e) => {
        e.preventDefault();
      }}
    >
      <div className="flex">
        <input
          type="search"
          id="search"
          name="search"
          placeholder="Search keywords..."
          className="min-w-0 flex-1 rounded-l-md bg-slate-300 px-md py-sm outline-0 ring-1 ring-inset ring-slate-200 placeholder:text-slate-100 focus:ring-2 focus:ring-purple-200"
          value={query}
          onChange={(event) => {
            if (callback) {
              callback(event.target.value);
            }
          }}
        />
        <button
          onClick={onClose}
          className="rounded-r-md border border-l-0 border-slate-200 bg-slate-300 px-md py-sm"
        >
          <SearchIcon />
        </button>
      </div>
    </form>
  );
}
