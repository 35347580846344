import { useState } from "react";
import { Link, useLocation } from "react-router-dom";
import Cart from "../components/cart";
import Popup from "../components/popup";
import Search from "../components/search";
import Upload from "./uploads";
import MenuIcon from "../icons/menu";
import UploadIcon from "../icons/upload";
import CartIcon from "../icons/cart";
import SearchIcon from "../icons/search";
import { useCart } from "../services/CartContext";

interface Props {
  user: any;
  searchQuery?: string;
  menuButtonClicked: () => void;
  searchCallback?: (query: string) => void;
}

interface Links {
  text: string;
  href: string;
}

const links: Links[] = [
  { text: "Browse", href: "/browse" },
  { text: "Subscriptions", href: "/subscriptions" },
];

export default function Header({
  user,
  searchQuery,
  menuButtonClicked,
  searchCallback,
}: Props) {
  const location = useLocation();
  const [searchIsOpen, setSearchIsOpen] = useState(false);
  const [uploadIsOpen, setUploadIsOpen] = useState(false);
  const [cartIsOpen, setCartIsOpen] = useState(false);
  const { cart } = useCart();

  const toggleSearch = () => {
    setSearchIsOpen(!searchIsOpen);
  };

  const toggleUpload = () => {
    setUploadIsOpen(!uploadIsOpen);
  };

  const toggleCart = () => {
    setCartIsOpen(!cartIsOpen);
  };

  const handleSearch = (query: string) => {
    if (searchCallback) searchCallback(query);
  };

  return (
    <>
      <header className="sticky top-0 z-10 flex items-center justify-between gap-lg bg-slate-500 bg-opacity-90 px-lg py-md backdrop-blur-md">
        <button onClick={menuButtonClicked}>
          <MenuIcon />
        </button>

        {links.map((link, index) => (
          <Link
            key={index}
            to={link.href}
            className={`hidden lg:inline ${
              location.pathname === link.href
                ? "text-stone-100"
                : "text-slate-100"
            }`}
          >
            {link.text}
          </Link>
        ))}

        <div className="hidden flex-1 sm:block">
          {searchCallback && (
            <Search query={searchQuery} callback={handleSearch} />
          )}
        </div>

        <div className="flex gap-lg">
          <button onClick={toggleSearch} className="sm:hidden">
            <SearchIcon />
          </button>
          <Link to="/account">
            <img
              src={user?.user.avatar} // TODO: Replace with user avatar
              alt="User"
              width={24}
              height={24}
              className="rounded-full"
            />
          </Link>
          <button onClick={toggleUpload}>
            <UploadIcon />
          </button>
          <button onClick={toggleCart} className="flex">
            <CartIcon />
            {cart.length > 0 && (
              <span className="block h-xs w-xs rounded-full bg-purple-100"></span>
            )}
          </button>
        </div>
      </header>

      {searchIsOpen && (
        <Popup>
          <div className="bg-slate-400 p-lg">
            <Search
              query={searchQuery}
              onClose={toggleSearch}
              callback={handleSearch}
            />
          </div>
        </Popup>
      )}

      {uploadIsOpen && (
        <Popup>
          <Upload onClose={toggleUpload} />
        </Popup>
      )}

      {cartIsOpen && (
        <Popup>
          <Cart onClose={toggleCart} />
        </Popup>
      )}
    </>
  );
}
