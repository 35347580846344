import Stat from "../../components/stat";
import Table from "../../components/table";
import TableHead from "../../components/tablehead";
import TableRow from "../../components/tablerow";
import TableCell from "../../components/tablecell";
import CartIcon from "../../icons/cart";
import ContentIcon from "../../icons/browse";
import { useEffect, useState } from "react";
import { Api } from "../../services/Api";
import { Content } from "../../types/Content";
import Account from "../../icons/account";

interface StatProps {
  total_content: string;
  total_creators: string;
  total_purchases: string;
  latest_content: Content[];
  most_like_content: Content[];
}
export default function Dashboard() {
  const headers = ["Name", "Date Added", "Likes", "Owners"];
  const [stats, setStats] = useState<StatProps | null>(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    Api("/admins/dashboard").then((data) => {
      setStats(data);
      setLoading(false);
    });
  },[]);

  return (
    <div className="flex-1 space-y-md p-md">
      <div className="grid gap-md md:grid-cols-3">
        <Stat
          icon={<ContentIcon />}
          heading="Total Content"
          number={stats?.total_content || "0"}
          percent=""
          chipColour="success"
        />
        <Stat
          icon={<Account />}
          heading="Creators"
          number={stats?.total_creators || "0"}
          percent=""
          chipColour="success"
        />
        <Stat
          icon={<CartIcon />}
          heading="Purchases"
          number={stats?.total_purchases || "£0.00"}
          percent=""
          chipColour="danger"
        />
      </div>

      <div className="grid gap-md lg:grid-cols-2">
        <Table>
          <TableRow>
            {headers.map((header, index) => (
              <TableHead key={index}>
                <span
                  className={index !== 0 ? "block truncate text-right" : ""}
                >
                  {header}
                </span>
              </TableHead>
            ))}
          </TableRow>
          {loading == false && stats?.latest_content.map((content, index) => (
            <TableRow key={index}>
              <TableCell>
                <span
                  className={"block truncate"}
                >
                  {content.description.title}
                </span>
              </TableCell>
              <TableCell>
                <span
                  className={"block truncate text-right"}
                >
                  {Intl.DateTimeFormat('en-GB', {
                    dateStyle: 'short',
                    timeStyle: 'short',
                    timeZone: 'Europe/London',
                  }).format(new Date(content.description.created_at))}
                </span>
              </TableCell>
              <TableCell>
                <span
                  className={"block truncate text-right"}
                >
                  {content.like_count.toFixed(0)}
                </span>
              </TableCell>
              <TableCell>
                <span
                  className={"block truncate text-right"}
                >
                  {content.owner_count.toFixed(0)}
                </span>
              </TableCell>
            </TableRow>
          ))}
        </Table>

        <Table>
          <TableRow>
            {headers.map((header, index) => (
              <TableHead key={index}>
                <span
                  className={index !== 0 ? "block truncate text-right" : ""}
                >
                  {header}
                </span>
              </TableHead>
            ))}
          </TableRow>
          {loading == false && stats?.most_like_content.map((content, index) => (
            <TableRow key={index}>
            <TableCell>
              <span
                className={"block truncate"}
              >
                {content.description.title}
              </span>
            </TableCell>
            <TableCell>
              <span
                className={"block truncate text-right"}
              >
                {Intl.DateTimeFormat('en-GB', {
                  dateStyle: 'short',
                  timeStyle: 'short',
                  timeZone: 'Europe/London',
                }).format(new Date(content.description.created_at))}
              </span>
            </TableCell>
            <TableCell>
              <span
                className={"block truncate text-right"}
              >
                {content.like_count.toFixed(0)}
              </span>
            </TableCell>
            <TableCell>
              <span
                className={"block truncate text-right"}
              >
                {content.owner_count.toFixed(0)}
              </span>
            </TableCell>
          </TableRow>
          ))}
        </Table>
      </div>
    </div>
  );
}
