type inputType = "text" | "email" | "password" | "number";

interface Props {
  type: inputType;
  id: string;
  name?: string;
  value?: string;
  placeholder?: string;
  tabindex?: number;
  required?: boolean;
  disabled?: boolean;
  maxLength?: number;
  onFocus?: () => void;
  onBlur?: () => void;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
}

export default function Input({
  type,
  id,
  name,
  value,
  placeholder,
  tabindex,
  required,
  disabled,
  maxLength,
  onFocus,
  onBlur,
  onChange,
}: Props) {
  return (
    <input
      type={type}
      id={id}
      name={name}
      value={value}
      placeholder={placeholder}
      tabIndex={tabindex}
      disabled={disabled}
      maxLength={maxLength}
      {...(required && { required: true })}
      {...(disabled && { disabled: true })}
      className="block w-full rounded-md bg-slate-300 px-md py-sm outline-0 ring-1 ring-slate-200 placeholder:text-slate-100 focus:ring-2 focus:ring-purple-200 disabled:cursor-not-allowed disabled:bg-slate-400 disabled:text-slate-100"
      onFocus={onFocus}
      onBlur={onBlur}
      onChange={onChange}
    />
  );
}
