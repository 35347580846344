import { useEffect, useState } from "react";
import AddTag from "../../components/addtag";
import Button from "../../components/button";
import Checkbox from "../../components/checkbox";
import Popup from "../../components/popup";
import { Tag } from "../../types/Tag";
import { Api } from "../../services/Api";
import { platformEvents } from "../../services/eventbus/platformEvents";

interface TagEntryProps {
  tag: Tag;
  path: string;
  selected: boolean;
  selected_tags: number[];
  setSelectedTags: (tags: number[]) => void;
}

const TagEntry = ({
  tag,
  path,
  selected,
  selected_tags,
  setSelectedTags,
}: TagEntryProps) => {
  return (
    <div className="space-y-md">
      <div className="flex gap-md rounded-md bg-slate-400 p-md">
        <Checkbox
          selected={selected}
          id={tag.id.toString()}
          onChange={(isSelected: boolean) => {
            if (isSelected) {
              setSelectedTags([...selected_tags, tag.id]);
            } else {
              setSelectedTags(selected_tags.filter((id) => id !== tag.id));
            }
          }}
        />
        <span>{path}</span>
      </div>
    </div>
  );
};
export default function Tags() {
  const [addTagIsOpen, setAddTagIsOpen] = useState(false);
  const [tags, setTags] = useState<Tag[]>([]);
  const [selectedTags, setSelectedTags] = useState<number[]>([]);

  const toggleAddTag = () => {
    setAddTagIsOpen(!addTagIsOpen);
  };

  const handleDeleteTags = () => {
    if (selectedTags.length > 0) {
      Api("/admins/tags", {
        method: "DELETE",
        data: { tag_ids: selectedTags },
      })
        .then(() => {
          platformEvents.emit(
            "generalSuccess",
            "The selected tags were deleted successfully",
          );
          Api("/browse/tags").then((data) => {
            setTags(data);
          });
          setSelectedTags([]);
        })
        .catch((data) => {
          platformEvents.emit(
            "generalFailure",
            "Something went wrong whilst deleting the selected tags. Please check and try again.",
          );
          Api("/browse/tags").then((data) => {
            setTags(data);
          });
          setSelectedTags([]);
        });
    }
  };

  useEffect(() => {
    Api("/browse/tags").then((data) => {
      setTags(data);
    });
  }, []);

  useEffect(() => {
    const unsubscribeSuccess = platformEvents.on("generalSuccess", (_) => {
      Api("/browse/tags").then((data) => {
        setTags(data);
      });
    });

    const unsubscribeFailed = platformEvents.on("generalFailure", (_) => {
      Api("/browse/tags").then((data) => {
        setTags(data);
      });
    });

    return () => {
      unsubscribeSuccess();
      unsubscribeFailed();
    };
  }, []);

  return (
    <>
      <div className="flex-1 space-y-md p-md">
        <div className="flex gap-sm">
          <Button onClick={toggleAddTag}>Add</Button>
          <Button onClick={handleDeleteTags}>Delete</Button>
        </div>

        {tags.map((tag, index) => (
          <TagEntry
            key={index}
            tag={tag}
            path={tag.tag}
            selected_tags={selectedTags}
            selected={selectedTags.includes(tag.id)}
            setSelectedTags={setSelectedTags}
          />
        ))}
      </div>

      {addTagIsOpen && (
        <Popup>
          <AddTag onClose={toggleAddTag} />
        </Popup>
      )}
    </>
  );
}
