import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import { Sanctum } from "react-sanctum";
import "@fontsource-variable/inter";
import "./styles/global.css";
import reportWebVitals from "./reportWebVitals";
import axios from "axios";
import { Routes } from "./router";
import { CartProvider } from "./services/CartContext";

import {enableMapSet} from "immer"

enableMapSet()

export const axiosInstance = axios.create({
  withCredentials: true,
  withXSRFToken: true,
});

const sanctumConfig = {
  apiUrl: process.env.REACT_APP_API_ENDPOINT || "http://localhost/api",
  csrfCookieRoute: "csrf",
  signInRoute: "auth/login",
  signOutRoute: "auth/logout",
  userObjectRoute: "auth/user",
  axiosInstance: axiosInstance,
  usernameKey: "username",
};

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <Sanctum config={sanctumConfig}>
      <CartProvider>
        <BrowserRouter>
          <Routes />
        </BrowserRouter>
      </CartProvider>
    </Sanctum>
  </React.StrictMode>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
