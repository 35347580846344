import React, { FunctionComponent } from 'react'
import { createRoot } from 'react-dom/client';


import { HotspotElementProps } from '../useMarzipano'
import { HotspotContainer, Hotspot } from '../../types/Marzipano'


function createHotspot(hotspotContainer: HotspotContainer, element: React.ReactElement<HotspotElementProps>): Hotspot {
  const { transform, ...otherProps } = element.props

  const rootElement = document.createElement('div')
  const creatorRoot = createRoot(rootElement);

  const { yaw, pitch, radius } = transform.coords
  const opts = { perspective: { radius } }

  const hotspot = hotspotContainer.createHotspot(rootElement, { yaw, pitch }, opts)

  creatorRoot.render(React.cloneElement(element, otherProps))

  hotspot.show()

  return hotspot
}

function destroyHotspot(hotspotContainer: HotspotContainer, hotspot: Hotspot) {
  hotspotContainer.destroyHotspot(hotspot)
}

export { createHotspot, destroyHotspot }