import { useState } from "react";
import { Link } from "react-router-dom";
import Alert from "../../components/alert";
import Button from "../../components/button";
import Input from "../../components/input";
import { Api } from "../../services/Api";
import { set } from "lodash";

export default function Forgot() {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [success, setSuccess] = useState<string | null>(null);

  const handleSubmit = async (event: React.FormEvent) => {
    event.preventDefault();
    const formData = new FormData(event.target as HTMLFormElement);
    setLoading(true);
    setError(null);
    setSuccess(null);
    Api("/auth/forgot", {
      method: "POST",
      data: {
        email: formData.get("email") as string,
      },
    })
      .then((data) => {
        setSuccess(data.message);
        setLoading(false);
      })
      .catch((data) => {
        if (data.error) {
          setError(data.error);
        } else if (data.message) {
          setError(data.message);
        }
        setLoading(false);
      });
  };

  return (
    <div className="space-y-lg">
      <div className="space-y-sm text-center">
        <h1 className="text-xl">Forgot your password?</h1>
        <p className="text-slate-100">
          Please enter your email address and we'll send you a password reset
          link.
        </p>
      </div>

      <form onSubmit={handleSubmit} method="POST" className="space-y-lg">
        {error ? <Alert text={error} colour="danger" /> : null}
        {success ? <Alert text={success} colour="success" /> : null}

        {!success && (
          <>
            <div className="space-y-sm">
              <label htmlFor="email" className="block">
                Email
              </label>
              <Input
                type="email"
                id="email"
                name="email"
                placeholder="example@domain.com"
                required
              />
            </div>

            <Button block primary disabled={loading}>
              {loading ? "Loading..." : "Send password reset email"}
            </Button>

            <div className="border-t border-slate-200 pt-lg text-center">
              <span className="text-slate-100">Don't have an account? </span>
              <Link to="/register" className="text-purple-100 underline">
                Sign up
              </Link>
            </div>
          </>
        )}
      </form>
    </div>
  );
}
