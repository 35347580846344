import { useEffect, useState, useCallback } from "react";
import Add from "./add";
import Box from "./box";
import Button from "./button";
import Chip from "./chip";
import Dropdown from "./dropdown";
import Input from "./input";
import Textarea from "./textarea";
import { FileUploader } from "react-drag-drop-files";
import { performMultipartUpload } from "../services/Uploader";
import Alert from "./alert";
import { useSanctum } from "react-sanctum";
import { forEach } from "lodash";
import UploadIcon from "../icons/upload";
import { Api } from "../services/Api";
import { Category } from "../types/Category";
import { Tag } from "../types/Tag";
import Select from "./select";

const fileTypes = ["ZIP", "MP4", "JPG", "JPEG"];
const imageFileTypes = ["PNG", "JPG", "JPEG"];

interface Props {
  onClose: () => void;
}

export default function Upload({ onClose }: Props) {
  const { user } = useSanctum();
  const [loading, setLoading] = useState(false);
  const [currentStep, setCurrentStep] = useState(1);
  const [file, setFile] = useState<File | null>(null);
  const [title, setTitle] = useState("");
  const [titleLength, setTitleLength] = useState(0);
  const titleMaxLength = 200;
  const [description, setDescription] = useState("");
  const [descriptionLength, setDescriptionLength] = useState(0);
  const descriptionMaxLength = 600;
  const [imageFiles, setImageFiles] = useState<File[]>([]);
  const [imageFilePreviews, setImageFilePreviews] = useState<string[]>([]);
  const [tagDropdownIsOpen, setTagDropdownIsOpen] = useState(false);
  const [tags, setTags] = useState<[number, string][]>([]);
  const [selectedTags, setSelectedTags] = useState<[number, string][]>([]);
  const [progress, setProgress] = useState(0);
  const [error, setError] = useState("");
  const [price, setPrice] = useState(0);
  const [categoryDropdownIsOpen, setCategoryDropdownIsOpen] = useState(false);
  const [categories, setCategories] = useState<[number, string][]>([]);
  const [selectedCategories, setSelectedCategories] = useState<
    [number, string][]
  >([]);
  const [pathStrings, setPathStrings] = useState<string[]>([]);
  const [tagStrings, setTagStrings] = useState<string[]>([]);

  const handleCancel = () => {
    setCurrentStep(1);
    setFile(null);
    setTitle("");
    setDescription("");
    setTags([]);
    setLoading(false);
    onClose();
  };

  const handleNext = () => {
    if (currentStep < 4) setCurrentStep(currentStep + 1);
  };

  const handlePrevious = () => {
    if (currentStep > 1) setCurrentStep(currentStep - 1);
  };

  const handleAddFile = (file: any) => {
    setFile(file);
  };

  const handleAddImageFiles = (files: File[]) => {
    forEach(files, (file) => {
      if (imageFiles.length < 4) {
        console.log(file.type);
        if (
          imageFileTypes.includes(file.type.toUpperCase().replace("IMAGE/", ""))
        ) {
          setImageFiles((prevFiles) => [...prevFiles, file]);
          setImageFilePreviews([]);
        }
      }
    });
  };

  const showCategoryDropdown = () => {
    setCategoryDropdownIsOpen(true);
  };

  const hideCategoryDropdown = () => {
    setCategoryDropdownIsOpen(false);
  };

  const handleCategoryClick = (name: string) => {
    // Find the original tuple from categories based on name
    const category = categories.find(
      ([, categoryName]) => categoryName === name,
    );
    // Check if it is already selected and do nothing if it is
    if (category) {
      if (selectedCategories.includes(category)) {
        return;
      }
      // Add the selected category to the selectedCategories tuple array
      setSelectedCategories([...selectedCategories, category]);
    }
  };

  const handleRemoveCategory = (category: [number, string]) => {
    // Remove the tuple from the selectedCategories tuple array
    setSelectedCategories(selectedCategories.filter((c) => c !== category));
  };

  const showTagDropdown = () => {
    setTagDropdownIsOpen(true);
  };

  const hideTagDropdown = () => {
    setTagDropdownIsOpen(false);
  };

  const handleTagClick = (name: string) => {
    const tag = tags.find(([, tagName]) => tagName === name);
    if (tag) {
      if (selectedTags.includes(tag)) {
        return;
      }
      setSelectedTags([...selectedTags, tag]);
    }
  };

  const handleRemoveTag = (tag: [number, string]) => {
    setSelectedTags(selectedTags.filter((c) => c !== tag));
  };

  const flattenCategories = useCallback(
    (categories: Category[], path: string = ""): [number, string][] => {
      const result: [number, string][] = [];

      categories.forEach((category) => {
        const currentPath =
          path === "" ? category.name : `${path} > ${category.name}`;
        result.push([category.id, currentPath]);
        result.push(...flattenCategories(category.all_children, currentPath));
      });

      return result;
    },
    [],
  );

  useEffect(() => {
    Api("/browse/categories").then((data) => {
      setCategories(flattenCategories(data));
    });
    Api("/browse/tags").then((data: Array<Tag>) => {
      setTags(data.map((tag) => [tag.id, tag.tag]));
    });
  }, [flattenCategories]);

  useEffect(() => {
    setPathStrings(categories.map(([, path]) => path));
    setTagStrings(tags.map(([, tag]) => tag));
  }, [categories, tags]);

  useEffect(() => {
    forEach(imageFiles, (file) => {
      const reader = new FileReader();
      reader.onload = (e) => {
        setImageFilePreviews((prev) => [
          ...prev,
          btoa(e.target?.result as string),
        ]);
      };
      reader.readAsBinaryString(file);
    });
  }, [imageFiles]);

  const handleStartUpload = async () => {
    if (file) {
      setLoading(true);
      setError("");
      setCurrentStep(3); // Move to progress view
      try {
        await performMultipartUpload(
          title,
          description,
          selectedTags,
          file,
          price,
          imageFiles,
          selectedCategories,
          (uploaded, total) => {
            const progress = (uploaded / total) * 100;
            if (progress > 0) setProgress(progress);
          },
        );

        setCurrentStep(4); // Move to finished view
        setLoading(false);
      } catch (error: any) {
        setError(error.message);
        setCurrentStep(2); // Move back to details view
        setLoading(false);
      }
    }
  };

  if (currentStep === 1) {
    return (
      <Box size="xl">
        <div className="space-y-lg">
          <h2 className="text-center text-xl">Upload Files</h2>

          <div className="space-y-lg">
            <FileUploader
              classes="flex rounded-md border border-slate-200 bg-slate-300 p-xl cursor-pointer"
              handleChange={handleAddFile}
              name="file"
              types={fileTypes}
              multiple={false}
            >
              {!file ? (
                <div className="m-auto rounded-full bg-slate-200 p-lg">
                  <UploadIcon />
                </div>
              ) : (
                <div className="m-auto py-lg">
                  <p>{file.name}</p>
                </div>
              )}
            </FileUploader>

            <div className="flex flex-col justify-between gap-md md:flex-row md:items-center">
              <span>Drag and drop files or click to upload.</span>
              <div>
                <Button
                  onClick={() => {
                    setFile(null);
                  }}
                  block
                >
                  Clear
                </Button>
              </div>
            </div>

            <ul className="grid gap-md text-slate-100 md:grid-cols-2">
              <li className="flex items-center gap-sm">
                <span className="h-sm w-sm rounded-full bg-purple-100"></span>
                <span>Maximum 2GB</span>
              </li>
              <li className="flex items-center gap-sm">
                <span className="h-sm w-sm rounded-full bg-purple-100"></span>
                <span>Minimum 1920px wide</span>
              </li>
              <li className="flex items-center gap-sm">
                <span className="h-sm w-sm rounded-full bg-purple-100"></span>
                <span>Correct file format (zip, mp4 or jpg)</span>
              </li>
              <li className="flex items-center gap-sm">
                <span className="h-sm w-sm rounded-full bg-purple-100"></span>
                <span>Only media you own</span>
              </li>
            </ul>

            <div className="flex justify-between border-t border-slate-200 pt-lg">
              <Button onClick={handleCancel} disabled={loading}>
                Cancel
              </Button>
              <Button primary disabled={loading || !file} onClick={handleNext}>
                Next
              </Button>
            </div>
          </div>
        </div>
      </Box>
    );
  }

  if (currentStep === 2) {
    return (
      <Box size="xl">
        <div className="space-y-lg">
          <h2 className="text-center text-xl">Add details</h2>

          <div className="space-y-lg">
            {error && <Alert colour="danger" text={error} />}

            <div className="space-y-sm">
              <div className="flex justify-between">
                <div className="flex items-center gap-sm">
                  <span className="h-xs w-xs rounded-full bg-purple-100"></span>
                  <label htmlFor="title">Title (required)</label>
                </div>
                <span className="text-slate-100">
                  {titleLength}/{titleMaxLength}
                </span>
              </div>
              <Input
                type="text"
                id="title"
                placeholder="Title"
                onChange={(e) => {
                  setTitle(e.target.value);
                  setTitleLength(e.target.value.length);
                }}
                value={title}
                maxLength={titleMaxLength}
              />
            </div>

            <div className="space-y-sm">
              <div className="flex justify-between">
                <div className="flex items-center gap-sm">
                  <span className="h-xs w-xs rounded-full bg-purple-100"></span>
                  <label htmlFor="description">Description (required)</label>
                </div>
                <span className="text-slate-100">
                  {descriptionLength}/{descriptionMaxLength}
                </span>
              </div>
              <Textarea
                id="description"
                placeholder="Description"
                rows={5}
                minLength={1}
                maxLength={descriptionMaxLength}
                value={description}
                onChange={(e) => {
                  setDescription(e.target.value);
                  setDescriptionLength(e.target.value.length);
                }}
              />
            </div>

            <div className="space-y-sm">
              <div className="flex justify-between">
                <div className="flex items-center gap-sm">
                  <span className="h-xs w-xs rounded-full bg-purple-100"></span>
                  <label>Thumbnails (required)</label>
                </div>
                <span className="text-slate-100">
                  Upload at least 1 image, upto 4.
                </span>
              </div>
              <div className="space-y-md">
                <FileUploader
                  classes="flex rounded-md border border-slate-200 bg-slate-300 p-xl cursor-pointer"
                  handleChange={handleAddImageFiles}
                  name="images"
                  types={imageFileTypes}
                  multiple={true}
                  maxLength={4}
                >
                  <div className="m-auto rounded-full bg-slate-200 p-lg">
                    <UploadIcon />
                  </div>
                </FileUploader>

                <div className="flex flex-wrap items-start justify-center gap-sm">
                  {imageFilePreviews.map((base64, index) => (
                    <img
                      key={index}
                      src={"data:image/jpeg;base64," + base64}
                      alt={"Preview " + index}
                      width={75}
                      height={50}
                      className="rounded-sm"
                    />
                  ))}
                </div>

                <div className="flex flex-col justify-between gap-md md:flex-row md:items-center">
                  <span>Drag and drop files or click to upload.</span>
                  <div>
                    <Button
                      onClick={() => {
                        setImageFiles([]);
                        setImageFilePreviews([]);
                      }}
                      block
                    >
                      Clear
                    </Button>
                  </div>
                </div>
              </div>
            </div>

            {user && user.type !== "users" && (
              <div className="space-y-sm">
                <div className="flex items-center gap-sm">
                  <span className="h-xs w-xs rounded-full bg-purple-100"></span>
                  <label htmlFor="price">Price (required)</label>
                </div>
                <Input
                  type="number"
                  id="price"
                  placeholder="Price"
                  onChange={(e) => {
                    setPrice(+e.target.value);
                  }}
                  value={price.toString()}
                />
              </div>
            )}

            <div className="space-y-md">
              <div className="space-y-sm">
                <div className="flex items-center gap-sm">
                  <span className="h-xs w-xs rounded-full bg-purple-100"></span>
                  <label htmlFor="categories">Categories (required)</label>
                </div>
                <div className="relative">
                  <Select
                    options={pathStrings}
                    value=""
                    valueChanged={handleCategoryClick}
                  />
                </div>
              </div>
              <div className="flex flex-wrap gap-sm">
                {selectedCategories.map((category) => (
                  <Chip
                    key={"category-" + category}
                    colour="secondary"
                    text={category[1]}
                    onClick={() => handleRemoveCategory(category)}
                  />
                ))}
              </div>
            </div>

            <div className="space-y-md">
              <div className="space-y-sm">
                <div className="flex items-center gap-sm">
                  <span className="h-xs w-xs rounded-full bg-purple-100"></span>
                  <label htmlFor="tags">Tags (required)</label>
                </div>
                <div className="relative">
                  <Select
                    options={tagStrings}
                    value=""
                    valueChanged={handleTagClick}
                  />
                </div>
              </div>
              <div className="flex flex-wrap gap-sm">
                {selectedTags.map((tag) => (
                  <Chip
                    key={"tag-" + tag}
                    colour="secondary"
                    text={tag[1]}
                    onClick={() => handleRemoveTag(tag)}
                  />
                ))}
              </div>
            </div>

            <div className="flex justify-between border-t border-slate-200 pt-lg">
              <Button onClick={handlePrevious} disabled={loading}>
                Back
              </Button>
              <Button
                onClick={handleStartUpload}
                primary
                disabled={
                  !title ||
                  !description ||
                  tags.length === 0 ||
                  imageFiles.length === 0 ||
                  loading
                }
              >
                Upload
              </Button>
            </div>
          </div>
        </div>
      </Box>
    );
  }

  if (currentStep === 3) {
    return (
      <Box size="xl">
        <div className="space-y-lg">
          <h2 className="text-center text-xl">Uploading</h2>

          <div className="space-y-lg">
            <div className="flex flex-col items-center gap-md">
              <h3 className="text-lg">{progress.toFixed(2)}%</h3>
            </div>
          </div>
        </div>
      </Box>
    );
  }

  if (currentStep === 4) {
    return (
      <Box size="xl">
        <div className="space-y-lg">
          <h2 className="text-center text-xl">Complete</h2>

          <div className="space-y-lg">
            <div className="flex flex-col items-center gap-md">
              <p>Your upload was successful!</p>
            </div>
          </div>

          <div className="flex justify-center border-t border-slate-200 pt-lg">
            <Button onClick={handleCancel} primary>
              Exit
            </Button>
          </div>
        </div>
      </Box>
    );
  }
}
