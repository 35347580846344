interface Props {
  id: string;
  placeholder?: string;
  rows: number;
  minLength: number;
  maxLength: number;
  value: string;
  onChange?: (e: React.ChangeEvent<HTMLTextAreaElement>) => void;
}

export default function Textarea({
  id,
  placeholder,
  rows,
  minLength,
  maxLength,
  value,
  onChange,
}: Props) {
  return (
    <textarea
      id={id}
      placeholder={placeholder}
      rows={rows}
      minLength={minLength}
      maxLength={maxLength}
      className="block w-full resize-none rounded-md bg-slate-300 px-md py-sm outline-0 ring-1 ring-slate-200 placeholder:text-slate-100 focus:ring-2 focus:ring-purple-200"
      onChange={onChange}
      value={value}
    />
  );
}
