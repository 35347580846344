import { useEffect, useState } from "react";
import { Link, useSearchParams } from "react-router-dom";
import Alert from "../../components/alert";
import Button from "../../components/button";
import Input from "../../components/input";
import { Api } from "../../services/Api";

export default function Reset() {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [success, setSuccess] = useState<string | null>(null);
  const [searchParams] = useSearchParams();
  const [email, setEmail] = useState("");

  const handleSubmit = async (event: React.FormEvent) => {
    event.preventDefault();
    const formData = new FormData(event.target as HTMLFormElement);
    setLoading(true);
    setError(null);
    setSuccess(null);
    Api("/auth/reset", {
      method: "POST",
      data: {
        email: formData.get("email") as string,
        token: searchParams.get("token"),
        password: formData.get("password") as string,
        password_confirmation: formData.get("password_confirmation") as string,
      },
    })
      .then((data) => {
        setSuccess(data.message);
        setLoading(false);
      })
      .catch((data) => {
        if (data.error) {
          setError(data.error);
        } else if (data.message) {
          setError(data.message);
        }
        setLoading(false);
      });
  };

  useEffect(() => {
    Api(`/auth/reset/${searchParams.get("token")}`).then((data) => {
      if (data.email) {
        setEmail(data.email);
      }
    });
  }, [searchParams]);

  return (
    <div className="space-y-lg">
      <div className="space-y-sm text-center">
        <h1 className="text-xl">Reset your password</h1>
        <p className="text-slate-100">
          Please enter your new password below to reset your password.
        </p>
      </div>

      <form onSubmit={handleSubmit} method="POST" className="space-y-lg">
        {error ? <Alert text={error} colour="danger" /> : null}
        {success ? (
          <>
            <Alert text={success} colour="success" />
            <div className="border-t border-slate-200 pt-lg text-center">
              <Link to="/login" className="text-purple-100 underline">
                Return to Login
              </Link>
            </div>
          </>
        ) : null}

        {!success && (
          <>
            <div className="space-y-sm">
              <label htmlFor="email" className="block">
                Email
              </label>
              <Input
                type="email"
                id="email"
                name="email"
                placeholder="example@domain.com"
                required
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
            </div>

            <div className="space-y-sm">
              <label htmlFor="password" className="block">
                Password
              </label>
              <Input
                type="password"
                id="password"
                name="password"
                placeholder="Password..."
                required
              />
            </div>

            <div className="space-y-sm">
              <label htmlFor="password-confirmation" className="block">
                Password
              </label>
              <Input
                type="password"
                id="password-confirmation"
                name="password_confirmation"
                placeholder="Confirm Password..."
                required
              />
            </div>

            <Button block primary disabled={loading}>
              {loading ? "Loading..." : "Reset Password"}
            </Button>
          </>
        )}
      </form>
    </div>
  );
}
