export default function RoundButton({
  children,
  onClick,
  active,
}: {
  children: React.ReactNode;
  onClick: () => void;
  active?: boolean;
}) {
  return (
    <button
      onClick={onClick}
      className={`rounded-full border p-sm ${
        !active
          ? "border-slate-200 bg-slate-300"
          : "border-emerald-200 bg-emerald-300"
      }`}
    >
      {children}
    </button>
  );
}
