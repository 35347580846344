import { axiosInstance } from "..";
import { Api } from "./Api";

interface PresignedUrls {
  partUrls: string[];
  uploadId: string;
}

const getPresignedUrls = async (
  title: string,
  description: string,
  selectedTags: [number, string][],
  price: number,
  file: File,
  imageFiles: File[],
  selectedCategories: [number, string][],
): Promise<PresignedUrls> => {
  return new Promise(function (resolve, reject) {
    Api("/upload/get-presigned-urls", {
      method: "POST",
      headers: {
        "Content-Type": "multipart/form-data",
      },
      data: {
        filename: file.name,
        filesize: file.size,
        title: title,
        description: description,
        tags: selectedTags.map(([id, _]) => id),
        price: price,
        images: imageFiles,
        categories: selectedCategories.map(([id, _]) => id),
      },
    })
      .then((data) => {
        resolve(data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const performMultipartUpload = async (
  title: string,
  description: string,
  selectedTags: [number, string][],
  file: File,
  price: number,
  imageFiles: File[],
  selectedCategories: [number, string][],
  progressCallback?: (totalUploaded: number, fileSize: number) => void,
) => {
  // Check filesize is below 12GB
  if (file.size > 12 * 1024 * 1024 * 1024) {
    throw new Error("File size must be less than 12GB.");
  }

  let presignedUrls = await getPresignedUrls(
    title,
    description,
    selectedTags,
    price,
    file,
    imageFiles,
    selectedCategories,
  );

  if (
    presignedUrls.partUrls === undefined ||
    presignedUrls.partUrls.length === 0
  ) {
    throw new Error(
      "The upload could not be completed. Please try again or contact support.",
    );
  }

  const partSize = 512 * 1024 * 1024; //1024MB chunks
  const totalParts = Math.ceil(file.size / partSize);
  const uploadedParts: { ETag: any; PartNumber: number }[] = [];

  for (let i = 0; i < totalParts; i++) {
    progressCallback?.(0, file.size);
    const start = i * partSize;
    const end = Math.min(file.size, start + partSize);
    const part = file.slice(start, end);
    const url = presignedUrls.partUrls[i + 1];
    await axiosInstance
      .put(url, part, {
        headers: {
          "Content-Type": file.type,
        },
        withCredentials: false,
        withXSRFToken: false,
        onUploadProgress: function (progressEvent) {
          const currentPartProgress = progressEvent.loaded;
          const totalUploaded =
            uploadedParts.length * partSize + currentPartProgress;
          progressCallback?.(totalUploaded, file.size);
        },
      })
      .then((response) => {
        uploadedParts.push({
          ETag: response.headers["etag"],
          PartNumber: i + 1,
        });
      })
      .catch((error) => {
        throw new Error(
          "There was an error uploading the file. Please try again or contact support.",
        );
      });
  }

  if (uploadedParts.length === totalParts) {
    return await Api("/upload/complete", {
      method: "POST",
      data: {
        uploadId: presignedUrls.uploadId,
        parts: uploadedParts,
      },
    })
      .then((data) => {
        return data;
      })
      .catch((error) => {
        throw new Error(
          "There was an error completing the upload. Please try again or contact support.",
        );
      });
  }

  throw new Error(
    "The upload failed to complete. Please try again or contact support.",
  );
};
