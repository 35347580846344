import React, { useEffect, useState, useRef } from 'react'
import { Viewer as ViewerConstructor } from 'marzipano'

import { Scene, Hotspot, Viewer } from '../types/Marzipano'
import { useScenes } from './scenes'

export type Container<T> = Array<T> | Map<string, T> | Record<string, T>

export interface KeyedData {
  key?: string,
}

export interface SceneSpec extends KeyedData {
  isCurrent?: any,
  hotspots: Container<HotspotSpec>,
  imageUrl: string,
  movieUrl?: string,
  type: string,
  levels?: any,
  viewParams?: any,
  viewLimiter?: any,
}

export interface HotspotElementProps {
  transform: {
    coords: {
      yaw: number,
      pitch: number,
      radius: number,
    },
  },
}

export interface HotspotSpec extends KeyedData {
  element: React.ReactElement<HotspotElementProps>
}

export interface ViewerOpts {
  controls?: {
    mouseViewMode?: 'drag' | 'qtvr',
    dragMode?: 'pan' | 'pinch',
    scrollZoom?: boolean,
  },
  stage?: {
    antialias?: boolean,
    preserveDrawingBuffer?: boolean,
    generateMipmaps?: boolean,
  },
  cursors?: {
    drag?: {
      active?: string,
      inactive?: string,
      disabled?: string,
    },
  },
}

export interface UseMarzipanoProps {
  viewerOpts?: ViewerOpts,
  scenes: Container<SceneSpec>,
}

export interface UseMarzipanoResult {
  viewerCanvas: React.RefObject<HTMLDivElement>,
  scenes: Map<string, Scene>,
  hotspots: Map<string, Hotspot>,
  viewer: any
}

const defaultViewerOpts = {
  controls: {
    mouseViewMode: 'drag',
  },
}

function useMarzipano({ viewerOpts, scenes: sceneSpecs }: UseMarzipanoProps): UseMarzipanoResult {
  const viewerCanvasRef = useRef<HTMLDivElement>(null)

  // Viewer initialization
  const [viewer, setViewer] = useState<Viewer | null>(null)
  useEffect(() => {
    if (viewerCanvasRef.current !== null) {
      const viewer = new ViewerConstructor(viewerCanvasRef.current, viewerOpts ?? defaultViewerOpts)
      setViewer(viewer)
    }
  }, [viewerCanvasRef])

  // Scene Loading
  const [scenes, hotspots] = useScenes(viewer, sceneSpecs)

  return { viewerCanvas: viewerCanvasRef, scenes, hotspots, viewer }
}

export default useMarzipano