import { useState } from "react";
import CrossIcon from "../icons/cross";
import SuccessIcon from "../icons/success";
import InfoIcon from "../icons/info";
import FailIcon from "../icons/fail";
import { isBigIntLiteral } from "typescript";

interface Props {
  title: string;
  message: string;
  type: "success" | "warning" | "info" | "danger";
  timeout?: number;
}

export default function Toast({ title, message, type, timeout = 5000 }: Props) {
  const [visible, setVisible] = useState(true);
  let icon;
  let typeClass = "";

  switch (type) {
    case "success":
      icon = <SuccessIcon />;
      typeClass = "text-emerald-100";
      break;
    case "warning":
      icon = <FailIcon />;
      typeClass = "text-amber-100";
      break;
    case "info":
      icon = <InfoIcon />;
      typeClass = "text-purple-100";
      break;
    case "danger":
      icon = <FailIcon />;
      typeClass = "text-amber-100";
      break;
  }

  setTimeout(() => {
    setVisible(false);
  }, timeout);

  if (!visible) return null;

  return (
    <div className="flex items-start gap-md rounded-md bg-slate-300 p-md">
      <div className={typeClass}>{icon}</div>
      <div className="flex-1 space-y-sm">
        <span className={typeClass}>{title}</span>
        <p>{message}</p>
      </div>
      <button onClick={() => setVisible(false)}>
        <CrossIcon />
      </button>
    </div>
  );
}
