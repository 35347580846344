import { useEffect, useMemo, useReducer, useState } from 'react'
import { produce } from 'immer'
import { v4 as uuidv4 } from 'uuid'

import { Container, SceneSpec } from '../useMarzipano'
import { Hotspot, Scene, Viewer } from '../../types/Marzipano'
import { loadScene, unloadScene, switchScene } from './sceneLoading'
import useHotspots, { UseHotspotsInput } from '../hotspots/useHotspots'


type SceneCacheAction =
  | { type: 'ADD', key: string, scene: Scene }
  | { type: 'DELETE', key: string }
  | { type: 'DELETEALL' }

export type UseScenesResult = [Map<string, Scene>, Map<string, Hotspot>]

function useScenes(viewer: Viewer | null, inputScenes: Container<SceneSpec> = [], currentSceneKey?: string, sceneTransitionDuration?: number): UseScenesResult {
  const inputScenesAsMap = useMemo<Map<string, SceneSpec>>(() => {
    if (inputScenes instanceof Map) {
      return inputScenes
    }
    if (inputScenes instanceof Array) {
      return new Map(inputScenes.map((sceneSpec) => {
        const key = sceneSpec.key ?? uuidv4()
        return [key, sceneSpec]
      }))
    }
    return new Map(Object.entries(inputScenes))
  }, [inputScenes])
  const [sceneCache, dispatchToSceneCache] = useReducer((state: Map<string, Scene>, action: SceneCacheAction) => {
    return produce(state, draftSceneCache => {
      switch (action.type) {
        case 'ADD':
          draftSceneCache.set(action.key, action.scene)
          break
        case 'DELETE':
          draftSceneCache.delete(action.key)
          break
        case 'DELETEALL':
          draftSceneCache.clear()
          break
      }
    })
  }, new Map<string, Scene>())
  const [useHotspotsInput, setUseHotspotsInput] = useState<UseHotspotsInput | undefined>(undefined)

  useEffect(() => {
    if (viewer === null) return
    for (const [key, scene] of sceneCache.entries()) {
      if (!inputScenesAsMap.has(key)) {
        unloadScene(viewer, scene)
        dispatchToSceneCache({ type: 'DELETE', key })
      }
    }
    for (const [key, sceneSpec] of inputScenesAsMap.entries()) {
      let scene = sceneCache.get(key)
      if (scene === undefined) {
        scene = loadScene(viewer, sceneSpec)
        dispatchToSceneCache({ type: 'ADD', key, scene })
      }
      if (currentSceneKey !== undefined && currentSceneKey === key ||
          currentSceneKey === undefined && sceneSpec.isCurrent !== undefined) {
        switchScene(viewer, scene, sceneTransitionDuration)
        setUseHotspotsInput({
          hotspotContainer: scene.hotspotContainer(),
          hotspotSpecs: sceneSpec.hotspots,
        })
      }
    }
  }, [viewer, inputScenes])

  const hotspotCache = useHotspots(useHotspotsInput)

  return [sceneCache, hotspotCache]
}

export default useScenes