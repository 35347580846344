import { useBrowseLayoutContext } from "../../layout/browse";
import Card from "../../components/card";
import Sort from "../../components/sort";
import { useState, useCallback, useRef, useEffect } from "react";
import { Content } from "../../types/Content";
import { Category } from "../../types/Category";
import { useLocation } from "react-router-dom";
import { Api } from "../../services/Api";
import { useSanctum } from "react-sanctum";

const Subscriptions = () => {
  const {
    sort,
    setSort,
    sortOptions,
    selectedCategories,
    searchQuery,
    loading,
    setLoading,
  } = useBrowseLayoutContext();

  const location = useLocation();
  const { authenticated } = useSanctum();

  const [page, setPage] = useState<number>(0);
  const [maxPages, setMaxPages] = useState<number>(0);
  const [content, setContent] = useState<Content[]>([]);

  const [timerId, setTimerId] = useState<NodeJS.Timeout | null>(null);

  const sentinelRef = useRef(null);

  const refreshContent = useCallback(
    (clear = true) => {
      setLoading(true);
      let newContent = content;
      let current_page = page;
      current_page = clear ? 1 : page + 1;
      newContent = clear ? [] : newContent;
      setPage(current_page);
      const params = new URLSearchParams({
        ...(selectedCategories.length > 0 && {
          categories: selectedCategories.map((c: Category) => c.id).join(","),
        }),
        ...(sort && { sort: sort.toLowerCase() }),
        ...(searchQuery && { search: searchQuery }),
        ...(location.pathname === "/subscriptions" && { subscriptions: "1" }),
        page: current_page.toString(),
      });
      Api(`/browse?${params.toString()}`)
        .then((data) => {
          newContent.push(...data.data);
          if (data.data !== undefined) setContent(newContent);
          if (data.last_page) setMaxPages(data.last_page);
          if (data.current_page) setPage(data.current_page);
        })
        .finally(() => {
          setLoading(false);
        });
    },
    [
      content,
      location.pathname,
      page,
      searchQuery,
      selectedCategories,
      setLoading,
      sort,
    ],
  );

  useEffect(() => {
    if (timerId) clearTimeout(timerId);

    const newTimerId = setTimeout(() => {
      if (!authenticated) return;
      refreshContent();
    }, 500);
    setTimerId(newTimerId);

    return () => {
      if (timerId) clearTimeout(timerId);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchQuery]);

  useEffect(() => {
    setContent([]);
    setPage(1);
    if (!authenticated) return;
    refreshContent();
    // Prevent looping load
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [authenticated, sort, selectedCategories]);

  useEffect(() => {
    const options = {
      root: null,
      rootMargin: "0px",
      threshold: 0.1,
    };

    const observer = new IntersectionObserver(([entry]) => {
      if (entry.isIntersecting && page > 0 && page < maxPages && !loading) {
        if (!location.pathname.startsWith("/view")) {
          refreshContent(false);
        }
      }
    }, options);

    if (sentinelRef.current) {
      observer.observe(sentinelRef.current);
    }

    return () => observer.disconnect();
  }, [refreshContent, page, maxPages, loading, location]);

  return (
    <div className="flex-1 space-y-md p-md">
      <Sort
        initialOption={sort}
        options={sortOptions}
        textPrefix="Sort by"
        categories={selectedCategories}
        valueChanged={setSort}
      />
      <div className="grid gap-md sm:grid-cols-2 lg:grid-cols-3 2xl:grid-cols-4">
        {content &&
          content.map((contentItem, index) => (
            <Card key={index} content={contentItem} />
          ))}
      </div>
      <div
        ref={sentinelRef}
        style={{ height: "10px" }}
        className="bg-transparent mb-lg mt-lg flex items-center justify-center font-semibold text-slate-100"
      >
        {page === maxPages ? "You've reached the end!" : null}
      </div>
    </div>
  );
};

export default Subscriptions;
