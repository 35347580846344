import Toast from "./toast";
import React, {
  useRef,
  useState,
  useImperativeHandle,
  forwardRef,
} from "react";

interface MessageProps {
  title: string;
  message: string;
  type: "success" | "warning" | "info" | "danger";
}

interface Props {
  timeout: number;
}

const Toaster = forwardRef((props: Props, ref) => {
  const [count, setCount] = useState(0);
  const [toasts, setToasts] = useState<React.ReactElement<MessageProps>[]>([]);
  const toasterRef = useRef<HTMLDivElement>(null);

  const addToast = (
    title: string,
    message: string,
    type: "success" | "warning" | "info",
  ) => {
    const toast = (
      <Toast
        key={count}
        title={title}
        message={message}
        type={type}
        timeout={props.timeout}
      />
    );
    setToasts((prevToasts) => [...prevToasts, toast]);
    setCount(count + 1);
  };

  useImperativeHandle(ref, () => ({
    addToast,
  }));

  return (
    <div
      ref={toasterRef}
      className={`absolute bottom-0 right-0 z-40 w-full ${toasts.length > 0 ? `space-y-md p-md max-w-md`: ''}`}
      children={toasts}
    ></div>
  );
});

export default Toaster;
