import React, { useRef, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import DashboardIcon from "../icons/dashboard";
import AccountIcon from "../icons/account";
import ContentIcon from "../icons/browse";
import CreatorsIcon from "../icons/subscriptions";
import LibraryIcon from "../icons/featured";
import SignOutIcon from "../icons/signout";
import useOutsideBounds from "../services/useOutsideBounds";
import useSwipe from "../services/useSwipe";
import { useSanctum } from "react-sanctum";

interface Links {
  text: string;
  href: string;
  icon: React.ReactNode;
  requires_type: string[];
}

const links: Links[] = [
  {
    text: "Dashboard",
    href: "/dashboard",
    icon: <DashboardIcon />,
    requires_type: ["admins"],
  },
  {
    text: "Account",
    href: "/account",
    icon: <AccountIcon />,
    requires_type: ["users", "creators", "admins"],
  },
  {
    text: "Content",
    href: "/content",
    icon: <ContentIcon />,
    requires_type: ["creators", "admins", "users"],
  },
  {
    text: "Categories",
    href: "/categories",
    icon: <CreatorsIcon />,
    requires_type: ["admins"],
  },
  {
    text: "Tags",
    href: "/tags",
    icon: <CreatorsIcon />,
    requires_type: ["admins"],
  },
  {
    text: "Library",
    href: "/library",
    icon: <LibraryIcon />,
    requires_type: ["users"],
  },
  {
    text: "My Likes",
    href: "/likes",
    icon: <LibraryIcon />,
    requires_type: ["users", "creators", "admins"],
  },
  {
    text: "Users",
    href: "/users",
    icon: <AccountIcon />,
    requires_type: ["admins"],
  },
];

interface Props {
  outsideClicked: () => void;
}

export default function AccountSidebar({ outsideClicked }: Props) {
  const location = useLocation();
  const navigate = useNavigate();
  const { user, signOut } = useSanctum();

  const [position, setPosition] = useState({ x: 0, y: 0 });

  const handleSwipeMove = (delta: { x: number; y: number }) => {
    setPosition({ x: position.x + delta.x, y: position.y + delta.y });
  };

  const handleTouchEnd = () => {
    setPosition({ x: 0, y: 0 });
  };

  const handleThresholdMet = () => {
    outsideClicked();
    setPosition({ x: 0, y: 0 });
  };

  useSwipe({
    threshold: 150,
    direction: "left",
    touchStart: () => {},
    touchMove: handleSwipeMove,
    touchEnd: handleTouchEnd,
    thresholdMet: handleThresholdMet,
  });

  const wrapperRef = useRef(null);
  useOutsideBounds(wrapperRef, () => {
    outsideClicked();
  });

  const handleSignOut = () => {
    signOut().then(() => {
      navigate("/login");
    });
  };

  return (
    <aside
      style={{ left: `${position.x}px`, top: `${position.y}px` }}
      ref={wrapperRef}
      className="custom-scrollbar fixed z-20 flex h-full w-10/12 max-w-sm flex-col justify-between gap-sm overflow-y-auto bg-slate-500 px-md py-lg xl:static"
    >
      <div className="space-y-sm">
        {links.map((link, index) =>
          link.requires_type.includes(user?.type || "admins") ? (
            <Link
              key={index}
              to={link.href}
              className={`flex gap-sm rounded-md px-md py-sm ${
                location.pathname === link.href
                  ? "bg-slate-400 hover:bg-slate-300"
                  : "text-slate-100 hover:bg-slate-400"
              }`}
            >
              {link.icon}
              <span>{link.text}</span>
            </Link>
          ) : null,
        )}
      </div>
      <span
        onClick={handleSignOut}
        className="flex cursor-pointer gap-sm rounded-md px-md py-sm text-slate-100 hover:bg-slate-400"
      >
        <SignOutIcon />
        <span>Sign out</span>
      </span>
    </aside>
  );
}
